import React from "react";
import { Button } from "reactstrap";
import "./ButtonPrimary.scss";

const ButtonPrimary = ({ content, icon, className, onClick }) => {
  return (
    <Button
      color="warning"
      className={`${className} primary_button`}
      onClick={onClick || null}
    >
      {content}
      <span> {icon}</span>
      {/* {icon ? <img loading="lazy"src={icon} alt="Icon" className="ms-2 icon" /> : null} */}
    </Button>
  );
};

export default ButtonPrimary;
