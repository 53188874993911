import React from "react";
import { Col, Row } from "reactstrap";
import { benefit1, benefit2, benefit3, benefit4 } from "../../../assets/images";
import "./Benefits.scss";

const Benefits = () => {
  return (
    <section className="benefits p-2 p-lg-5">
      <p className="title text-center ">4 ways to know benefits</p>
      <p className="description">
        Here are some key and advantages of hiring professional packers and
        movers
      </p>
      <Row className="m-0 p-5">
        <Col lg="3" md="6" sm="6  ">
          <div className="box">
            <img loading="lazy"src={benefit1} alt="Time_Saving" />
            <p>Time Saving</p>
          </div>
        </Col>
        <Col lg="3" md="6" sm="6  ">
          <div className="box">
            <img loading="lazy"src={benefit2} alt="Insurance" />
            <p>Insurance</p>
          </div>
        </Col>
        <Col lg="3" md="6" sm="6  ">
          <div className="box">
            <img loading="lazy"src={benefit3} alt="Efficiecy_And_Expertise" />
            <p>Efficiency & Expertise</p>
          </div>
        </Col>
        <Col lg="3" md="6" sm="6  ">
          <div className="box">
            <img loading="lazy"src={benefit4} alt="On_Time_Delivery" />
            <p>On Time Delivery</p>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Benefits;
