import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import LandingPage from "./pages/LandingPage/LandingPage";
import CustomNavbar from "./components/layout/Navbar/Navbar";
import Footer from "./components/layout/Footer/Footer";
import Faq from "./pages/FAQs/Faq";
import AboutUs from "./pages/AboutUs/AboutUs";
import Clients from "./pages/Clients/Clients";
import ContactUs from "./pages/ContactUs/ContactUs";
import Tracking from "./pages/Tracking/Tracking";
import WhatsappButton from "./components/layout/WhatsappButton/WhatsappButton";
import CallButton from "./components/layout/CallButton/CallButton";
import Chatbot from "./components/layout/Chatbot/ChatBot";
import HeavyMachineLifting from "./pages/Services/HeavyMachineLifting";
import TransitInsurance from "./pages/Services/TransitInsurance";
import CarTranportation from "./pages/Services/CarTransportation";
import IntercityMoves from "./pages/Services/IntercityMoves";
import InternationalRelocation from "./pages/Services/InternationalRelocation";
import LoadingAndUnloading from "./pages/Services/LoadingAndUnloading";
import OfficeRelocation from "./pages/Services/OfficeRelocation";
import TransportationService from "./pages/Services/TransportationService";
import WarehouseAndStorage from "./pages/Services/WarehouseAndStorage";
import BikeTransportation from "./pages/Services/BikeTransportation";
import { useEffect } from "react";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import Citywise from "./pages/Citywise/Citywise";
import Bhagalpur from "./pages/CitywisePages/Bhagalpur";
import Munger from "./pages/CitywisePages/Munger";
import Jamalpur from "./pages/CitywisePages/Jamalpur";
import Banka from "./pages/CitywisePages/Banka";
import Godda from "./pages/CitywisePages/Godda";
import Khagaria from "./pages/CitywisePages/Khagaria";
import Purnia from "./pages/CitywisePages/Purnia";
import Katihar from "./pages/CitywisePages/Katihar";
import Dumka from "./pages/CitywisePages/Dumka";
import Patna from "./pages/CitywisePages/Patna";
import Begusaria from "./pages/CitywisePages/Begusaria";
import Forbisganj from "./pages/CitywisePages/Forbisganj";
import Darbhanga from "./pages/CitywisePages/Darbhanga";
import Samastipur from "./pages/CitywisePages/Samastipur";
import Muzaffarpur from "./pages/CitywisePages/Muzaffarpur";
import Bojpur from "./pages/CitywisePages/Bojpur";
import Barouni from "./pages/CitywisePages/Barouni";
import Nawada from "./pages/CitywisePages/Nawada";
import Sultanganj from "./pages/CitywisePages/Sultanganj";
import Madhubani from "./pages/CitywisePages/Madhubani";
import Betiha from "./pages/CitywisePages/Betiha";
import Araria from "./pages/CitywisePages/Araia";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  return (
    <div className="app-container">
      <ScrollToTop />
      <CustomNavbar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/faqs" element={<Faq />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/tracking" element={<Tracking />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/city-wise" element={<Citywise />} />
        <Route
          path="/city-wise/packers-movers-in-bhagalpur"
          element={<Bhagalpur />}
        />
        <Route
          path="/city-wise/packers-movers-in-araria"
          element={<Araria />}
        />
        <Route
          path="/city-wise/packers-movers-in-munger"
          element={<Munger />}
        />
        <Route
          path="/city-wise/packers-movers-in-jamalpur"
          element={<Jamalpur />}
        />
        <Route path="/city-wise/packers-movers-in-banka" element={<Banka />} />
        <Route path="/city-wise/packers-movers-in-godda" element={<Godda />} />
        <Route
          path="/city-wise/packers-movers-in-khagaria"
          element={<Khagaria />}
        />
        <Route
          path="/city-wise/packers-movers-in-purnia"
          element={<Purnia />}
        />
        <Route
          path="/city-wise/packers-movers-in-katihar"
          element={<Katihar />}
        />
        <Route path="/city-wise/packers-movers-in-dumka" element={<Dumka />} />
        <Route path="/city-wise/packers-movers-in-patna" element={<Patna />} />
        <Route
          path="/city-wise/packers-movers-in-begusarai"
          element={<Begusaria />}
        />
        <Route
          path="/city-wise/packers-movers-in-forbisganj"
          element={<Forbisganj />}
        />
        <Route
          path="/city-wise/packers-movers-in-darbhanga"
          element={<Darbhanga />}
        />
        <Route
          path="/city-wise/packers-movers-in-samastipur"
          element={<Samastipur />}
        />
        <Route
          path="/city-wise/packers-movers-in-muzaffarpur"
          element={<Muzaffarpur />}
        />
        <Route
          path="/city-wise/packers-movers-in-bojpur"
          element={<Bojpur />}
        />
        <Route
          path="/city-wise/packers-movers-in-barouni"
          element={<Barouni />}
        />
        <Route
          path="/city-wise/packers-movers-in-nawada"
          element={<Nawada />}
        />
        <Route
          path="/city-wise/packers-movers-in-sultanganj"
          element={<Sultanganj />}
        />
        <Route
          path="/city-wise/packers-movers-in-madhubani"
          element={<Madhubani />}
        />
        <Route
          path="/city-wise/packers-movers-in-betiha"
          element={<Betiha />}
        />
        <Route
          path="/services/heavy-machinery-shifting"
          element={<HeavyMachineLifting />}
        />
        <Route
          path="/services/transit-insurance"
          element={<TransitInsurance />}
        />
        <Route
          path="/services/bike-transportation"
          element={<BikeTransportation />}
        />
        <Route
          path="/services/car-transportation"
          element={<CarTranportation />}
        />{" "}
        <Route path="/services/intercity-moves" element={<IntercityMoves />} />{" "}
        <Route
          path="/services/international-relocation"
          element={<InternationalRelocation />}
        />{" "}
        <Route
          path="/services/loading-and-unloading"
          element={<LoadingAndUnloading />}
        />{" "}
        <Route
          path="/services/office-relocation"
          element={<OfficeRelocation />}
        />{" "}
        <Route
          path="/services/transportation-services"
          element={<TransportationService />}
        />{" "}
        <Route
          path="/services/warehousing-and-storage"
          element={<WarehouseAndStorage />}
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
      <div className="whatsapp-button-container" style={{ bottom: "100px" }}>
        <CallButton />
      </div>

      <div className="whatsapp-button-container">
        <WhatsappButton />
      </div>
      <div
        className="call-button-container"
        style={{ right: "20px", left: null }}
      >
        <Chatbot />
      </div>
    </div>
  );
}

export default App;
