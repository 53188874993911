import React, { useState } from "react";
import "./EnquiryForm.scss";
import { Button, FormGroup, Label } from "reactstrap";
import ButtonPrimary from "../../common/ButtonPrimary/ButtonPrimary";
import { Input } from "antd";
import TextArea from "antd/es/input/TextArea";

const EnquiryForm = () => {
  const [formType, setFormType] = useState("home");
  const [fromCity, setFromCity] = useState("");
  const [toCity, setToCity] = useState("");
  const [configuration, setConfiguration] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [personalDetails, setPersonalDetails] = useState({
    name: "",
    mobile: "",
    email: "",
    dob: "",
    enquiry: "",
  });
  const handleSubmit = () => {
    console.log("Form Type:", formType);
    console.log("From City:", fromCity);
    console.log("To City:", toCity);
    console.log("Configuration:", configuration);
    console.log("Personal Details:", personalDetails);
    const requestData = {
      formType: formType,
      fromCity: fromCity,
      toCity: toCity,
      configuration: configuration,
      yourName: personalDetails.name,
      mobileNo: personalDetails.mobile,
      email: personalDetails.email,
      dob: personalDetails.dob,
      enquiry: personalDetails.enquiry,
    };
    // Here you would typically handle backend submission via an API call
    fetch("https://aestraswift.ocpl.tech/api2/form/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        alert("Thank you for your query!");
        // Reset form fields after successful submission
        setFromCity("");
        setToCity("");
        setConfiguration("");
        setPersonalDetails({
          name: "",
          mobile: "",
          email: "",
          dob: "",
          enquiry: "",
        });
        // Return to step 1
        setCurrentStep(1);
      })
      .then((data) => {
        console.log("API Response:", data);
        // Handle successful response here
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle error here
      });
  };

  // Determine the placeholder based on formType
  const getConfigurationPlaceholder = () => {
    switch (formType) {
      case "home":
        return "1BHK/2BHK/3BHK";
      case "office":
        return "10/20 or more employees";
      case "car":
        return "Car model";
      default:
        return "";
    }
  };

  // Render form title based on formType
  const renderFormTitle = () => {
    switch (formType) {
      case "home":
        return "Book Home Relocation";
      case "office":
        return "Book Office Relocation";
      case "car":
        return "Book Car Shifting";
      default:
        return "";
    }
  };

  // Handle the transition to the second form step
  const handleNextClick = () => {
    setCurrentStep(2);
  };

  const renderFormStep = () => {
    if (currentStep === 1) {
      return (
        <div className="outer-box mt-4 w-100 ">
          <h2 className="heading">{renderFormTitle()}</h2>
          <div className="d-flex justify-content-between w-100 flex-column flex-lg-row">
            <FormGroup className="me-4 mt-1 mt-lg-5">
              <Label for="fromCity">From City</Label>
              <Input
                id="fromCity"
                size="large"
                name="fromCity"
                placeholder="From City"
                type="text"
                value={fromCity}
                onChange={(e) => setFromCity(e.target.value)}
              />
            </FormGroup>
            <FormGroup className="me-4 mt-1 mt-lg-5">
              <Label for="toCity">To City</Label>
              <Input
                id="toCity"
                name="toCity"
                size="large"
                placeholder="To City"
                type="text"
                value={toCity}
                onChange={(e) => setToCity(e.target.value)}
              />
            </FormGroup>
            <FormGroup className="me-4 mt-1 mt-lg-5">
              <Label for="configuration">Configuration</Label>
              <Input
                id="configuration"
                name="configuration"
                size="large"
                placeholder={getConfigurationPlaceholder()}
                type="text"
                value={configuration}
                onChange={(e) => setConfiguration(e.target.value)}
              />
            </FormGroup>
            <FormGroup className="flex-column d-flex mt-1 mt-lg-5">
              <Label for="getQuote">Get Quote</Label>
              <ButtonPrimary
                content="Next"
                className="px-4 py-1 h6"
                onClick={handleNextClick}
              />
            </FormGroup>
          </div>
        </div>
      );
    } else if (currentStep === 2) {
      return (
        <div className="outer-box mt-4 ">
          <h2 className="heading">Personal Detail</h2>
          <FormGroup>
            <Label for="name">Your Name</Label>
            <Input
              id="name"
              size="large"
              name="name"
              placeholder="Your Name"
              type="text"
              value={personalDetails.name}
              onChange={(e) =>
                setPersonalDetails({ ...personalDetails, name: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="mobile">Mobile Number</Label>
            <Input
              id="mobile"
              size="large"
              name="mobile"
              placeholder="Mobile Number"
              type="text"
              value={personalDetails.mobile}
              onChange={(e) =>
                setPersonalDetails({
                  ...personalDetails,
                  mobile: e.target.value,
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email Id</Label>
            <Input
              id="email"
              size="large"
              name="email"
              placeholder="Email Id"
              type="email"
              value={personalDetails.email}
              onChange={(e) =>
                setPersonalDetails({
                  ...personalDetails,
                  email: e.target.value,
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="dob">Date of Birth</Label>
            <Input
              id="dob"
              size="large"
              name="dob"
              placeholder="dd/mm/yyyy"
              type="date"
              value={personalDetails.dob}
              onChange={(e) =>
                setPersonalDetails({ ...personalDetails, dob: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="enquiry">Your Enquiry</Label>
            <TextArea
              rows={4}
              id="enquiry"
              size="large"
              name="enquiry"
              placeholder="Your Enquiry"
              type="text"
              value={personalDetails.enquiry}
              onChange={(e) =>
                setPersonalDetails({
                  ...personalDetails,
                  enquiry: e.target.value,
                })
              }
            />
          </FormGroup>
          {/* 
          <ButtonPrimary
            content="Back"
            className="px-4 py-0 h6"
            onClick={() => setCurrentStep(1)}
          /> */}
          <div className="d-flex justify-content-between">
            <ButtonPrimary
              content="Back"
              className="px-4 py-1 h6"
              onClick={() => setCurrentStep(1)}
            />
            <ButtonPrimary
              content="Submit"
              className="px-4 py-1 h6"
              onClick={handleSubmit}
            />
          </div>
        </div>
      );
    }
  };
  const step2Styles = currentStep === 2 ? { height: "850px" } : {};

  return (
    <section
      className="enquiryForm position-relative d-flex justify-content-center align-items-center"
      style={step2Styles}
      id="enquiryForm"
    >
      <div
        className={`bg-container ${
          formType === "home"
            ? "active bg-1"
            : formType === "office"
            ? "active bg-2"
            : formType === "car"
            ? "active bg-3"
            : ""
        }`}
      ></div>
      <div className="position-absolute form p-1 p-lg-5">
        {currentStep === 1 && (
          <div className="d-flex gap-1">
            <Button
              color="light"
              className={`px-4 py-2 button-form ${
                formType === "home" ? "active" : ""
              }`}
              onClick={() => setFormType("home")}
            >
              Home
            </Button>
            <Button
              color="light"
              className={`px-4 py-2 button-form ms-3 ${
                formType === "office" ? "active" : ""
              }`}
              onClick={() => setFormType("office")}
            >
              Office
            </Button>
            <Button
              color="light"
              className={`px-4 py-2 button-form ms-3 ${
                formType === "car" ? "active" : ""
              }`}
              onClick={() => setFormType("car")}
            >
              Car
            </Button>
          </div>
        )}

        {renderFormStep()}
        {/* <ButtonSecondary
          content="Download Form"
          className="mt-4 mt-lg-5 w-100 py-1 d-flex justify-content-center align-items-center"
          icon={<MdOutlineFileDownload size="25" />}
        /> */}
      </div>
    </section>
  );
};

export default EnquiryForm;
