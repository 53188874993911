import React from "react";
import "./index.scss";
import CustomBreadcrumb from "../../components/common/Breadcrumb/Custom_Breadcrumb";
import { Col, Row } from "reactstrap";
import { call, transporation } from "../../assets/images";
import { Link } from "react-router-dom";

const TransportationService = () => {
  return (
    <section className="services">
      <CustomBreadcrumb
        name="Our Services"
        secondName="Transportation Service"
      />
      <Row className="m-0 mt-5 p-3 p-lg-5">
        <Col lg="8">
          <img
            src={transporation}
            alt="Transportation_Service"
            className="w-100"
          />
          <p className="service_name mt-5">Transportation Service</p>
          <p className="service_description mt-3">
            Welcome to our transportation services, where we specialize in
            providing efficient and dependable transportation solutions for all
            your moving needs. Whether you're relocating across town or across
            the country, we're here to ensure that your belongings arrive safely
            and on time at their destination.
          </p>
        </Col>
        <Col lg="4">
          <div className="our-services p-3 p-lg-5">
            <p className="header">Our Services</p>
            <ul>
              <li>
                <Link to="/services/international-relocation">
                  International Relocation
                </Link>
              </li>
              <li>
                <Link to="/services/transportation-services">
                  Transportation Services
                </Link>
              </li>
              <li>
                <Link to="/services/warehousing-and-storage">
                  Warehousing and Storage
                </Link>
              </li>
              <li>
                <Link to="/services/loading-and-unloading">
                  Loading and Unloading
                </Link>
              </li>
              <li>
                <Link to="/services/office-relocation">Office Relocation</Link>
              </li>
              <li>
                <Link to="/services/car-transportation">
                  Car Transportation
                </Link>
              </li>
              <li>
                <Link to="/services/bike-transportation">
                  Bike Transportation
                </Link>
              </li>
              <li>
                <Link to="/services/intercity-moves">Intercity Moves</Link>
              </li>
              <li>
                <Link to="/services/transit-insurance">Transit Insurance</Link>
              </li>
              <li>
                <Link to="/services/heavy-machinery-shifting">
                  Heavy Machinery Shifting
                </Link>
              </li>
            </ul>
          </div>
        </Col>
        <Col lg="12">
          <p className="sub-head">
            Why Choose Us for Your Transportation Needs?
          </p>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Professionalism</p>
            <p className="content">
              Our team of experienced drivers and movers are dedicated to
              providing the highest level of professionalism and customer
              service. We take pride in our work and go above and beyond to
              ensure that your moving experience is stress-free and seamless
              from start to finish.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Safety</p>
            <p className="content">
              The safety of your belongings is our top priority. We use modern,
              well-maintained vehicles equipped with state-of-the-art safety
              features to transport your items safely and securely. You can
              trust us to handle your belongings with care and attention to
              detail throughout the transportation process.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Efficiency</p>
            <p className="content">
              We understand that time is of the essence when it comes to moving,
              which is why we prioritize efficiency in everything we do. Our
              drivers are skilled at navigating safely and efficiently to your
              destination, minimizing downtime and ensuring a smooth transition
              to your new location.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Flexibility</p>
            <p className="content">
              Whether you're moving locally or long-distance, we offer flexible
              transportation solutions to meet your needs. From small apartment
              moves to large corporate relocations, we have the resources and
              expertise to handle moves of all sizes and complexities.
            </p>
          </div>
        </Col>
        <Col lg="12">
          <p className="sub-head">Our Transportation Services</p>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Local Moves</p>
            <p className="content">
              If you're moving within the same city or town, our local
              transportation services are perfect for you. Our drivers are
              familiar with the local area and can navigate quickly and
              efficiently to your new location.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Long-Distance Moves</p>
            <p className="content">
              If you're moving to a new city or state, our long-distance
              transportation services are here to help. We'll coordinate all the
              logistics of your move, from planning and packing to
              transportation and delivery, ensuring a smooth and hassle-free
              transition to your new home or office.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Specialized Moves</p>
            <p className="content">
              Do you have items that require special handling, such as pianos,
              antiques, or fragile artwork? Our specialized transportation
              services are equipped to handle all types of items with care and
              precision, ensuring that they arrive safely and undamaged at their
              destination.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Corporate Relocations</p>
            <p className="content">
              Planning a corporate relocation? Our transportation services are
              tailored to meet the unique needs of businesses, including office
              equipment, furniture, and technology. We'll work with you to
              develop a customized transportation plan that minimizes downtime
              and disruptions to your business operations.
            </p>
          </div>
        </Col>
        <Col lg="12">
          <p className="content text-black mt-4">
            At KHALSA PACKERS AND MOVERS PVT LTD ], we're committed to providing
            top-quality transportation services that you can trust. Whether
            you're moving locally or long-distance, residential or commercial,
            you can rely on us to handle your transportation needs with
            professionalism and care.
          </p>
          <Link to="/#enquiryForm" className="text-black text-decoration-none">
            <div className="button-conclusion d-flex justify-content-center mt-5 align-items-center">
              <img loading="lazy"src={call} alt="Call" className="me-4" />
              <p className="text-black text-decoration-none">
                Contact us today to learn more about our transportation services
                and how we can help you with your next move.
              </p>
            </div>
          </Link>
        </Col>
      </Row>
    </section>
  );
};

export default TransportationService;
