import React from "react";
import Slider from "react-slick";
import "./ClientSlider.scss";

const ClientSlider = () => {
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 700,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 910,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 610,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="client-slider p-5 ">
      <Slider {...settings}>
        {[...Array(55)].map((_, index) => (
          <img
            key={index}
            src={`/clients/client${index + 1}.jpg`}
            alt={`client${index + 1}`}
            className="client_logo p-3"
          />
        ))}
      </Slider>
    </section>
  );
};

export default ClientSlider;
