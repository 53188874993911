import React from "react";
import "./Footer.scss";
import { Col, Row } from "reactstrap";
import {
  call_yellow,
  footer_slider1,
  footer_slider2,
  footer_slider3,
  footer_slider4,
  footer_slider5,
  footer_slider6,
  location,
  logo,
  mail_yellow,
  whatsapp,
} from "../../../assets/images";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const Footer = () => {
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    cssEase: "linear",
    slidesToShow: 1,
    fade: true,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 910,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const currentYear = new Date().getFullYear();
  return (
    <section className="">
      <Row className="m-0 footer">
        <Col lg="3" md="6" sm="12">
          <img loading="lazy"src={logo} alt="Company_Logo" className="img-fluid" />
          <p className="about_company mt-5">
            We are offering smooth and world-class packing and moving services
            from Bhagalpur according to your all relocation needs.
          </p>
          <div className="d-flex mt-5 flex-row social_icons">
            <Link
              to="https://www.facebook.com/profile.php?id=100090667013975"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="47"
                height="46"
                viewBox="0 0 47 46"
                fill="none"
                className="me-3"
              >
                <circle
                  cx="23.7697"
                  cy="22.7801"
                  r="21.7801"
                  stroke="#FFC800"
                  stroke-width="2"
                />
                <path
                  d="M25.6633 22.9949H24.6633V23.9949V34.4978H22.1745V23.9949V22.9949H21.1745H18.4211V20.5106H21.1745H22.1745V19.5106V16.204C22.1745 14.4839 22.6801 13.2767 23.4392 12.5029C24.1978 11.7297 25.3179 11.2783 26.7655 11.2783C27.6805 11.2783 28.5157 11.3201 29.1182 11.364V13.4652L27.8178 13.4657C27.8177 13.4657 27.8176 13.4657 27.8174 13.4657C26.7726 13.4658 25.8726 13.722 25.2883 14.438C24.7426 15.1067 24.6633 15.9609 24.6633 16.6481V19.5106V20.5106H25.6633H28.8337L28.5227 22.9949H25.6633Z"
                  fill="#FFC800"
                  stroke="#FFC800"
                  stroke-width="2"
                />
              </svg>
            </Link>
            <Link
              to="https://www.instagram.com/khalsapackersandmovers/?igsh=MWp0ZTMxcHpqdXlkZg%3D%3D"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="46"
                height="46"
                viewBox="0 0 46 46"
                fill="none"
                className="me-3"
              >
                <circle
                  cx="23.2198"
                  cy="22.7801"
                  r="21.7801"
                  stroke="#FFC800"
                  stroke-width="2"
                />
                <path
                  d="M19.8052 22.7281C19.8052 20.9643 21.1873 19.584 22.8233 19.584C24.4606 19.584 25.8456 20.9658 25.8456 22.7281C25.8456 24.492 24.4592 25.8768 22.8233 25.8768C21.1887 25.8768 19.8052 24.4934 19.8052 22.7281Z"
                  fill="#FFC800"
                  stroke="#FFC800"
                  stroke-width="2"
                />
                <path
                  d="M16.759 12.4297H28.8875C29.3231 12.4297 29.744 12.5053 30.138 12.645C30.1156 12.6444 30.0931 12.6441 30.0706 12.6441C28.6612 12.6441 27.5677 13.8172 27.5677 15.1942C27.5677 15.5197 27.6286 15.8331 27.7402 16.1222C26.3796 15.0507 24.677 14.4111 22.8233 14.4111C18.33 14.4111 14.7249 18.1684 14.7249 22.7278C14.7249 27.2864 18.3292 31.049 22.8233 31.049C27.3173 31.049 30.9216 27.2864 30.9216 22.7278C30.9216 20.7883 30.2692 18.9939 29.1737 17.5722C29.4508 17.6803 29.7528 17.7398 30.0706 17.7398C31.4762 17.7398 32.5735 16.5751 32.5735 15.1942C32.5735 15.1886 32.5735 15.183 32.5734 15.1775C32.7056 15.5831 32.7775 16.0186 32.7775 16.4732V28.9825C32.7775 31.2434 31.0055 33.0259 28.8875 33.0259H16.759C14.6449 33.0259 12.869 31.2428 12.869 28.9825V16.4732C12.869 14.2168 14.6454 12.4297 16.759 12.4297Z"
                  fill="#FFC800"
                  stroke="#FFC800"
                  stroke-width="2"
                />
              </svg>
            </Link>
            <Link
              to="https://whatsapp.com/channel/0029Vab42N07oQhVvvJVHf3F"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="46"
                height="46"
                viewBox="0 0 46 46"
                fill="none"
                className="me-3"
              >
                <circle
                  cx="22.7801"
                  cy="22.7801"
                  r="21.7801"
                  stroke="#FFC800"
                  stroke-width="2"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M31.6222 13.3421C29.3418 11.059 26.309 9.80114 23.0781 9.7998C16.4205 9.7998 11.0022 15.218 10.9995 21.8774C10.9986 24.0062 11.5547 26.0842 12.6118 27.9159L10.8982 34.1748L17.3012 32.4952C19.0655 33.4576 21.0518 33.9647 23.0732 33.9653H23.0783C29.7351 33.9653 35.154 28.5467 35.1566 21.887C35.1579 18.6595 33.9027 15.625 31.6222 13.3421ZM23.0781 31.9255H23.0739C21.2726 31.9248 19.5059 31.4407 17.9643 30.5261L17.5979 30.3085L13.7982 31.3053L14.8124 27.6007L14.5736 27.2208C13.5687 25.6224 13.038 23.775 13.0389 21.8781C13.041 16.343 17.5446 11.8398 23.0821 11.8398C25.7636 11.8407 28.2843 12.8863 30.1796 14.7839C32.075 16.6815 33.1182 19.2038 33.1173 21.8863C33.1149 27.4218 28.6116 31.9255 23.0781 31.9255ZM28.5848 24.4067C28.2831 24.2555 26.7992 23.5256 26.5225 23.4248C26.2461 23.324 26.0446 23.2739 25.8437 23.5759C25.6424 23.8779 25.0641 24.5578 24.8879 24.7591C24.7118 24.9605 24.5359 24.9858 24.2341 24.8347C23.9322 24.6837 22.9598 24.3648 21.807 23.3367C20.9099 22.5364 20.3042 21.5481 20.1281 21.2461C19.9522 20.9438 20.1266 20.7962 20.2605 20.6302C20.5872 20.2245 20.9143 19.7991 21.0149 19.5979C21.1156 19.3964 21.0652 19.2201 20.9896 19.0691C20.9143 18.9181 20.3108 17.4326 20.0593 16.8281C19.8142 16.2399 19.5656 16.3193 19.3802 16.3101C19.2043 16.3013 19.0031 16.2996 18.8018 16.2996C18.6006 16.2996 18.2736 16.375 17.9969 16.6773C17.7203 16.9795 16.9408 17.7095 16.9408 19.195C16.9408 20.6805 18.0222 22.1155 18.1731 22.317C18.3239 22.5184 20.3013 25.5668 23.3286 26.8739C24.0487 27.1851 24.6108 27.3707 25.0492 27.5098C25.7722 27.7395 26.43 27.707 26.9501 27.6294C27.53 27.5426 28.7355 26.8992 28.9872 26.1943C29.2387 25.4893 29.2387 24.8851 29.1631 24.7591C29.0878 24.6332 28.8865 24.5578 28.5848 24.4067Z"
                  fill="#FFC800"
                />
              </svg>
            </Link>
            <Link
              to="https://www.youtube.com/@Khalsa_Packers_And_Movers"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="46"
                height="46"
                viewBox="0 0 46 46"
                fill="none"
                className="me-3"
              >
                <circle
                  cx="23.2199"
                  cy="22.7801"
                  r="21.7801"
                  stroke="#FFC800"
                  stroke-width="2"
                />
                <path
                  d="M33.8257 18.1104C33.5772 17.1866 32.8489 16.4585 31.9253 16.2097C30.238 15.748 23.4885 15.748 23.4885 15.748C23.4885 15.748 16.7393 15.748 15.052 16.1922C14.1461 16.4407 13.4 17.1868 13.1516 18.1104C12.7075 19.7976 12.7075 23.2966 12.7075 23.2966C12.7075 23.2966 12.7075 26.8133 13.1516 28.4829C13.4003 29.4065 14.1283 30.1347 15.0521 30.3834C16.7571 30.8452 23.4888 30.8452 23.4888 30.8452C23.4888 30.8452 30.238 30.8452 31.9253 30.401C32.8491 30.1524 33.5772 29.4243 33.826 28.5006C34.2699 26.8133 34.2699 23.3144 34.2699 23.3144C34.2699 23.3144 34.2877 19.7976 33.8257 18.1104ZM21.3396 26.5292V20.0641L26.9521 23.2966L21.3396 26.5292Z"
                  fill="#FFC800"
                />
              </svg>
            </Link>
          </div>
        </Col>
        <Col lg="3" md="6" sm="12">
          <div className="contact_us">
            <p className="heading text-uppercase mt-5 mt-lg-0 mt-md-0">
              Contact Us
            </p>
            <div className="row m-0 mt-4">
              <div className="col-lg-1 col-1">
                <img loading="lazy"src={call_yellow} alt="Call_Icon" />
              </div>
              <div className="col-lg-11 col-11">
                <p className="sub_heading">Phone</p>
                <p className="content">+91 8541893616, +91 6200086395</p>
              </div>
            </div>
            <div className="row m-0 mt-4">
              <div className="col-lg-1 col-1">
                <img loading="lazy"src={whatsapp} alt="Whatsapp_Icon" />
              </div>
              <div className="col-lg-11 col-11">
                <p className="sub_heading">Whatsapp</p>
                <p className="content">+91 8541893616</p>
              </div>
            </div>
            <div className="row m-0 mt-4">
              <div className="col-lg-1 col-1">
                <img loading="lazy"src={mail_yellow} alt="Mail_Icon" />
              </div>
              <div className="col-lg-11 col-11">
                <p className="sub_heading">Email</p>
                <p className="content">info@khalsapackersmovers.com</p>
              </div>
            </div>
            <div className="row m-0 mt-4">
              <div className="col-lg-1 col-1">
                <img loading="lazy"src={location} alt="Location_Icon" />
              </div>
              <div className="col-lg-11 col-11">
                <p className="sub_heading">Office Address</p>
                <p className="content">
                  Trimurti Chowk, GC Banerjee Road, Near Pushpanjali Sweets,
                  Bhikhanpur, Bhagalpur, Bihar 812001
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col lg="3" md="6" sm="12">
          <div className="links mt-5 mt-lg-0 mt-md-0">
            {" "}
            <p className="heading text-uppercase">QUICK LINKS</p>
            <div className="mt-4 d-flex flex-column">
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                className="mt-3 text-decoration-none text-white"
                to="/tracking"
              >
                {" "}
                TRACKING
              </Link>
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                className="mt-3 text-decoration-none text-white"
                to="/services/international-relocation"
              >
                {" "}
                OUR SERVICES
              </Link>
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                className="mt-3 text-decoration-none text-white"
                to="/city-wise"
              >
                {" "}
                CITY WISE
              </Link>
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                className="mt-3 text-decoration-none text-white"
                to="/contact-us"
              >
                {" "}
                CONTACT US
              </Link>
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                className="mt-3 text-decoration-none text-white"
                to="/terms-and-conditions"
              >
                {" "}
                TERMS & CONDITIONS
              </Link>
            </div>
          </div>
        </Col>
        <Col lg="3" md="6" sm="12">
          <Slider {...settings} className="mt-5 mt-lg-0 mt-md-0">
            <img
              src={footer_slider1}
              alt="Footer_Slider"
              className="footer-slider"
            />
            <img
              src={footer_slider2}
              alt="Footer_Slider"
              className="footer-slider"
            />
            <img
              src={footer_slider3}
              alt="Footer_Slider"
              className="footer-slider"
            />
            <img
              src={footer_slider4}
              alt="Footer_Slider"
              className="footer-slider"
            />
            <img
              src={footer_slider5}
              alt="Footer_Slider"
              className="footer-slider"
            />
            <img
              src={footer_slider6}
              alt="Footer_Slider"
              className="footer-slider"
            />
          </Slider>
        </Col>
      </Row>
      <Row className="m-0 ">
        <Col lg="12" className="p-0">
          <div className="copyright">
            ©{currentYear}. Khalsa Packers & Movers. Designed by{" "}
            <Link
              className="text-decoration-none company_link"
              to="https://ocpl.tech"
              target="_blank"
            >
              OCPL Tech
            </Link>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Footer;

// border-radius: 20px;
