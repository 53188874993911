import React from "react";
import "./index.scss";
import CustomBreadcrumb from "../../components/common/Breadcrumb/Custom_Breadcrumb";
import { Col, Row } from "reactstrap";
import { call, transitInsurance } from "../../assets/images";
import { Link } from "react-router-dom";

const TransitInsurance = () => {
  return (
    <section className="services">
      <CustomBreadcrumb name="Our Services" secondName="Transit Insurance" />
      <Row className="m-0 mt-5 p-3 p-lg-5">
        <Col lg="8">
          <img
            src={transitInsurance}
            alt="Intercity_Movies"
            className="w-100"
          />
          <p className="service_name mt-5">Transit Insurance</p>
          <p className="service_description mt-3">
            Welcome to our transit insurance services, where we offer
            comprehensive coverage to protect your belongings during transit.
            Moving can be a stressful experience, but with our transit
            insurance, you can have peace of mind knowing that your items are
            safeguarded against unforeseen events during transportation.
          </p>
        </Col>
        <Col lg="4">
          <div className="our-services p-3 p-lg-5">
            <p className="header">Our Services</p>
            <ul>
              <li>
                <Link to="/services/international-relocation">
                  International Relocation
                </Link>
              </li>
              <li>
                <Link to="/services/transportation-services">
                  Transportation Services
                </Link>
              </li>
              <li>
                <Link to="/services/warehousing-and-storage">
                  Warehousing and Storage
                </Link>
              </li>
              <li>
                <Link to="/services/loading-and-unloading">
                  Loading and Unloading
                </Link>
              </li>
              <li>
                <Link to="/services/office-relocation">Office Relocation</Link>
              </li>
              <li>
                <Link to="/services/car-transportation">
                  Car Transportation
                </Link>
              </li>
              <li>
                <Link to="/services/bike-transportation">
                  Bike Transportation
                </Link>
              </li>
              <li>
                <Link to="/services/intercity-moves">Intercity Moves</Link>
              </li>
              <li>
                <Link to="/services/transit-insurance">Transit Insurance</Link>
              </li>
              <li>
                <Link to="/services/heavy-machinery-shifting">
                  Heavy Machinery Shifting
                </Link>
              </li>
            </ul>
          </div>
        </Col>
        <Col lg="12">
          <p className="sub-head">Why Choose Transit Insurance?</p>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Protection Against Loss or Damage</p>
            <p className="content">
              Transit insurance provides coverage for your belongings in the
              event of loss, theft, or damage during transit. Whether you're
              moving locally or long-distance, transit insurance ensures that
              your items are protected against unforeseen accidents or mishaps.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Peace of Mind</p>
            <p className="content">
              Moving can be a time of uncertainty, but transit insurance offers
              peace of mind by providing financial protection for your
              belongings. With coverage in place, you can relax knowing that
              you're financially protected against the unexpected.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Comprehensive Coverage Options</p>
            <p className="content">
              We offer a range of coverage options to suit your needs and
              budget. Whether you're looking for basic coverage or comprehensive
              protection, we have insurance plans tailored to meet your
              requirements.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Professional Handling</p>
            <p className="content">
              While we take every precaution to ensure the safe transport of
              your belongings, accidents can still happen. Transit insurance
              provides an added layer of protection, giving you confidence that
              your items are covered in the event of an unforeseen incident.
            </p>
          </div>
        </Col>
        <Col lg="12">
          <p className="sub-head">Our Transit Insurance Coverage</p>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Loss or Damage</p>
            <p className="content">
              Our transit insurance covers your belongings against loss or
              damage caused by accidents, theft, or unforeseen events during
              transit.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Comprehensive Protection</p>
            <p className="content">
              Our insurance plans offer comprehensive protection for your
              belongings, including coverage for items of all types and values.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Flexible Coverage Options</p>
            <p className="content">
              We offer flexible coverage options to suit your needs and budget.
              Whether you're moving locally or long-distance, we have insurance
              plans tailored to meet your requirements.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Peace of Mind</p>
            <p className="content">
              With transit insurance in place, you can have peace of mind
              knowing that your belongings are protected against unforeseen
              accidents or mishaps during transit.
            </p>
          </div>
        </Col>
        <Col lg="12">
          <p className="content text-black mt-4">
            At KHALSA PACKERS AND MOVERS PVT LTD , we understand the importance
            of protecting your belongings during transit. That's why we offer
            comprehensive transit insurance coverage to give you peace of mind
            during your move.
          </p>
          <Link to="/#enquiryForm" className="text-black text-decoration-none">
            <div className="button-conclusion d-flex justify-content-center mt-5 align-items-center">
              <img loading="lazy"src={call} alt="Call" className="me-4" />
              <p className="text-black text-decoration-none">
                Contact us today to learn more about our transit insurance
                options and how we can help you safeguard your belongings during
                transit.
              </p>
            </div>
          </Link>
        </Col>
      </Row>
    </section>
  );
};

export default TransitInsurance;
