import React, { useEffect, useState } from "react";
import "./Testimonials.scss";
import { Container } from "reactstrap";
import Slider from "react-slick";
// import { anurag, dwarika, kewal, pragati } from "../../../assets/images";
import ButtonPrimary from "../../common/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../common/ButtonSecondary/ButtonSecondary";
import { Link } from "react-router-dom";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { IoIosSend } from "react-icons/io";

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    fetch("https://aestraswift.ocpl.tech/api2/profiles")
      .then((response) => response.json())
      .then((data) => {
        setTestimonials(
          data.data.map((testimonial, index) => ({
            ...testimonial,
            bg: index % 2 === 1, // Alternating bg values
          }))
        );
      })
      .catch((error) => console.error("Error fetching testimonials:", error));
  }, []);
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  // const data = [
  //   {
  //     name: "Dwarika Sahani",
  //     review:
  //       "I used Khalsa Packers and Movers for the second time for my move from Bhagalpur to Bhubaneswar. It was a reliable and positive experience. The packing was excellent, and all my packages were delivered safely. I highly recommend them; they're the best in the business.",
  //     image: dwarika,
  //   },
  //   {
  //     name: "Anurag Sinha",
  //     review:
  //       "The team at Khalsa Packers and Movers is trained, professional, and offers top-notch service in Bhagalpur for household moves. I was pleased with their service for my move from Katihar to Bangalore.",
  //     image: anurag,
  //     bg: true,
  //   },
  //   {
  //     name: "Pragati Priya",
  //     review:
  //       "They were incredibly helpful throughout the entire shifting process, and their professionalism was up to the mark. From packing to unpacking, every step was handled excellently. I highly recommend them for their professionalism and positive attitude.",
  //     image: pragati,
  //   },
  //   {
  //     name: "Kewal Kumar",
  //     review:
  //       "The packaging was expertly done, and the staff was both courteous and skilled. The package was delivered on time. Overall, the service was of high quality and reliable.",
  //     image: kewal,
  //     bg: true,
  //   },
  // ];
  return (
    <Container className="testimonials my-5">
      <Slider {...settings}>
        {testimonials.map((item, index) => {
          return (
            <div
              key={index}
              className={`outer-div text-center ${
                index % 2 === 1 ? "bg-primary" : ""
              }`}
            >
              <p className="heading">What Our Client Says</p>
              <p className="sub-head mt-3">
                Our Clients send us bunch of smiles with our services and we
                love them
              </p>
              <div className="text-center d-flex justify-content-center my-1 my-lg-4 mt-3 mt-lg-5">
                <img
                  src={item.profilePicUrl}
                  alt="Reviewer"
                  className="user_image"
                />
              </div>
              <div className="stars my-1 my-lg-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="200"
                  height="40"
                  viewBox="0 0 213 46"
                  fill="none"
                >
                  <path
                    d="M20.1468 8.78115C21.0449 6.01722 24.9551 6.01722 25.8532 8.78115L27.4903 13.8197C27.8919 15.0557 29.0438 15.8926 30.3434 15.8926H35.6413C38.5474 15.8926 39.7557 19.6115 37.4046 21.3197L33.1186 24.4336C32.0671 25.1976 31.6272 26.5517 32.0288 27.7877L33.6659 32.8262C34.5639 35.5902 31.4005 37.8885 29.0494 36.1803L24.7634 33.0664C23.7119 32.3024 22.2881 32.3024 21.2366 33.0664L16.9506 36.1803C14.5995 37.8885 11.4361 35.5902 12.3341 32.8262L13.9712 27.7877C14.3728 26.5517 13.9329 25.1976 12.8814 24.4336L8.5954 21.3197C6.24425 19.6115 7.45258 15.8926 10.3587 15.8926H15.6565C16.9562 15.8926 18.1081 15.0557 18.5097 13.8197L20.1468 8.78115Z"
                    fill="#FFD426"
                  />
                  <path
                    d="M61.724 8.78115C62.622 6.01722 66.5323 6.01722 67.4303 8.78115L69.0674 13.8197C69.4691 15.0557 70.6209 15.8926 71.9206 15.8926H77.2184C80.1246 15.8926 81.3329 19.6115 78.9818 21.3197L74.6957 24.4336C73.6443 25.1976 73.2043 26.5517 73.6059 27.7877L75.243 32.8262C76.1411 35.5902 72.9777 37.8885 70.6265 36.1803L66.3405 33.0664C65.289 32.3024 63.8653 32.3024 62.8138 33.0664L58.5278 36.1803C56.1766 37.8885 53.0132 35.5902 53.9113 32.8262L55.5484 27.7877C55.95 26.5517 55.51 25.1976 54.4586 24.4336L50.1725 21.3197C47.8214 19.6115 49.0297 15.8926 51.9359 15.8926H57.2337C58.5334 15.8926 59.6852 15.0557 60.0869 13.8197L61.724 8.78115Z"
                    fill="#FFD426"
                  />
                  <path
                    d="M103.301 8.78115C104.199 6.01722 108.109 6.01722 109.007 8.78115L110.644 13.8197C111.046 15.0557 112.198 15.8926 113.497 15.8926H118.795C121.701 15.8926 122.91 19.6115 120.558 21.3197L116.272 24.4336C115.221 25.1976 114.781 26.5517 115.183 27.7877L116.82 32.8262C117.718 35.5902 114.554 37.8885 112.203 36.1803L107.917 33.0664C106.866 32.3024 105.442 32.3024 104.39 33.0664L100.104 36.1803C97.7533 37.8885 94.5899 35.5902 95.4879 32.8262L97.125 27.7877C97.5267 26.5517 97.0867 25.1976 96.0352 24.4336L91.7492 21.3197C89.3981 19.6115 90.6064 15.8926 93.5126 15.8926H98.8104C100.11 15.8926 101.262 15.0557 101.664 13.8197L103.301 8.78115Z"
                    fill="#FFD426"
                  />
                  <path
                    d="M144.878 8.78115C145.776 6.01722 149.686 6.01722 150.584 8.78115L152.221 13.8197C152.623 15.0557 153.775 15.8926 155.074 15.8926H160.372C163.278 15.8926 164.487 19.6115 162.136 21.3197L157.85 24.4336C156.798 25.1976 156.358 26.5517 156.76 27.7877L158.397 32.8262C159.295 35.5902 156.131 37.8885 153.78 36.1803L149.494 33.0664C148.443 32.3024 147.019 32.3024 145.968 33.0664L141.682 36.1803C139.33 37.8885 136.167 35.5902 137.065 32.8262L138.702 27.7877C139.104 26.5517 138.664 25.1976 137.612 24.4336L133.326 21.3197C130.975 19.6115 132.184 15.8926 135.09 15.8926H140.388C141.687 15.8926 142.839 15.0557 143.241 13.8197L144.878 8.78115Z"
                    fill="#FFD426"
                  />
                  <path
                    d="M186.454 8.78115C187.353 6.01722 191.263 6.01722 192.161 8.78115L193.798 13.8197C194.2 15.0557 195.351 15.8926 196.651 15.8926H201.949C204.855 15.8926 206.063 19.6115 203.712 21.3197L199.426 24.4336C198.375 25.1976 197.935 26.5517 198.336 27.7877L199.974 32.8262C200.872 35.5902 197.708 37.8885 195.357 36.1803L191.071 33.0664C190.02 32.3024 188.596 32.3024 187.544 33.0664L183.258 36.1803C180.907 37.8885 177.744 35.5902 178.642 32.8262L180.279 27.7877C180.68 26.5517 180.24 25.1976 179.189 24.4336L174.903 21.3197C172.552 19.6115 173.76 15.8926 176.666 15.8926H181.964C183.264 15.8926 184.416 15.0557 184.817 13.8197L186.454 8.78115Z"
                    fill="#FFD426"
                  />
                </svg>
              </div>
              <div className="d-flex">
                {item.bg ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="116"
                    height="96"
                    viewBox="0 0 116 96"
                    fill="none"
                    className="m--3"
                  >
                    <path
                      d="M48.9938 0C50.1946 3.5955 50.795 6.59176 50.795 8.98878C50.795 11.1461 50.4348 14.0225 49.7143 17.618C33.6232 17.618 25.5776 25.8876 25.5776 42.427C25.5776 48.4195 28.6998 51.7753 34.9441 52.4944C44.0704 53.2135 48.6335 59.0861 48.6335 70.1124C48.6335 78.5019 46.5921 84.9738 42.5093 89.5281C38.6667 93.8427 31.5818 96 21.2547 96C7.08489 96 0 86.412 0 67.236C0 48.0599 3.84265 32.1199 11.528 19.4157C19.2132 6.47191 31.7019 0 48.9938 0ZM114.199 0C115.4 3.5955 116 6.59176 116 8.98878C116 11.1461 115.64 14.0225 114.919 17.618C98.8281 17.618 90.7826 25.8876 90.7826 42.427C90.7826 48.4195 93.9048 51.7753 100.149 52.4944C109.275 53.2135 113.839 59.0861 113.839 70.1124C113.839 78.5019 111.797 84.9738 107.714 89.5281C103.872 93.8427 96.7867 96 86.4596 96C72.2898 96 65.205 86.412 65.205 67.236C65.205 48.0599 69.0476 32.1199 76.7329 19.4157C84.4182 6.47191 96.9068 0 114.199 0Z"
                      fill="#FFD426"
                      fill-opacity="0.25"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="116"
                    height="96"
                    viewBox="0 0 116 96"
                    fill="none"
                    className="m--3"
                  >
                    <path
                      d="M48.9938 0C50.1946 3.5955 50.795 6.59176 50.795 8.98878C50.795 11.1461 50.4348 14.0225 49.7143 17.618C33.6232 17.618 25.5776 25.8876 25.5776 42.427C25.5776 48.4195 28.6998 51.7753 34.9441 52.4944C44.0704 53.2135 48.6335 59.0861 48.6335 70.1124C48.6335 78.5019 46.5921 84.9738 42.5093 89.5281C38.6667 93.8427 31.5818 96 21.2547 96C7.08489 96 0 86.412 0 67.236C0 48.0599 3.84265 32.1199 11.528 19.4157C19.2132 6.47191 31.7019 0 48.9938 0ZM114.199 0C115.4 3.5955 116 6.59176 116 8.98878C116 11.1461 115.64 14.0225 114.919 17.618C98.8281 17.618 90.7826 25.8876 90.7826 42.427C90.7826 48.4195 93.9048 51.7753 100.149 52.4944C109.275 53.2135 113.839 59.0861 113.839 70.1124C113.839 78.5019 111.797 84.9738 107.714 89.5281C103.872 93.8427 96.7867 96 86.4596 96C72.2898 96 65.205 86.412 65.205 67.236C65.205 48.0599 69.0476 32.1199 76.7329 19.4157C84.4182 6.47191 96.9068 0 114.199 0Z"
                      fill="#ECECEC"
                    />
                  </svg>
                )}

                <div className="my-3 content">{item.description}</div>
                {item.bg ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="88"
                    height="73"
                    viewBox="0 0 88 73"
                    fill="none"
                    className="margin-bottom"
                  >
                    <path
                      d="M50.8323 73C49.9213 70.2659 49.4658 67.9875 49.4658 66.1648C49.4658 64.5243 49.7391 62.3371 50.2857 59.603C62.4928 59.603 68.5963 53.3146 68.5963 40.7378C68.5963 36.181 66.2277 33.6292 61.4907 33.0824C54.5673 32.5356 51.1056 28.0699 51.1056 19.6854C51.1056 13.3059 52.6542 8.38452 55.7516 4.92135C58.6667 1.64045 64.0414 2.09453e-06 71.8758 1.40962e-06C82.6253 4.69875e-07 88 7.29089 88 21.8727C88 36.4544 85.0849 48.5755 79.2547 58.236C73.4244 68.0787 63.9503 73 50.8323 73ZM1.36648 73C0.455498 70.2659 7.19119e-06 67.9875 7.03184e-06 66.1648C6.88843e-06 64.5243 0.273307 62.3371 0.819892 59.603C13.0269 59.603 19.1304 53.3146 19.1304 40.7378C19.1304 36.181 16.7619 33.6292 12.0248 33.0824C5.10146 32.5356 1.63976 28.0699 1.63976 19.6854C1.63976 13.3059 3.18841 8.38452 6.28571 4.92136C9.20083 1.64046 14.5756 6.41897e-06 22.4099 5.73406e-06C33.1594 4.79432e-06 38.5342 7.29089 38.5342 21.8727C38.5342 36.4544 35.6191 48.5755 29.7888 58.236C23.9586 68.0787 14.4845 73 1.36648 73Z"
                      fill="#FFD426"
                      fill-opacity="0.25"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="88"
                    height="73"
                    viewBox="0 0 88 73"
                    fill="none"
                    className="margin-bottom"
                  >
                    <path
                      d="M50.8323 73C49.9213 70.2659 49.4658 67.9875 49.4658 66.1648C49.4658 64.5243 49.7391 62.3371 50.2857 59.603C62.4928 59.603 68.5963 53.3146 68.5963 40.7378C68.5963 36.181 66.2277 33.6292 61.4907 33.0824C54.5673 32.5356 51.1056 28.0699 51.1056 19.6854C51.1056 13.3059 52.6542 8.38452 55.7516 4.92135C58.6667 1.64045 64.0414 2.09453e-06 71.8758 1.40962e-06C82.6253 4.69875e-07 88 7.29089 88 21.8727C88 36.4544 85.0849 48.5755 79.2547 58.236C73.4244 68.0787 63.9503 73 50.8323 73ZM1.36648 73C0.455498 70.2659 7.19119e-06 67.9875 7.03184e-06 66.1648C6.88843e-06 64.5243 0.273307 62.3371 0.819892 59.603C13.0269 59.603 19.1304 53.3146 19.1304 40.7378C19.1304 36.181 16.7619 33.6292 12.0248 33.0824C5.10146 32.5356 1.63976 28.0699 1.63976 19.6854C1.63976 13.3059 3.18841 8.38452 6.28571 4.92136C9.20083 1.64046 14.5756 6.41897e-06 22.4099 5.73406e-06C33.1594 4.79432e-06 38.5342 7.29089 38.5342 21.8727C38.5342 36.4544 35.6191 48.5755 29.7888 58.236C23.9586 68.0787 14.4845 73 1.36648 73Z"
                      fill="#ECECEC"
                    />
                  </svg>
                )}
              </div>
            </div>
          );
        })}
      </Slider>
      <div className="row mt-3 mt-lg-5 ">
        <div className="col-lg-6 col-12">
          <Link to="https://g.co/kgs/XXQ3mtk">
            <ButtonSecondary
              content="View All Reviews"
              icon={<FaRegArrowAltCircleRight />}
              className="w-100 w-lg-75 px-5 p-0 py-2 py-lg-2"
            />
          </Link>
        </div>
        <div className="col-lg-6 text-end col-12 mt-2 mt-lg-0 ">
          <Link to="https://g.page/r/CU4SjjBOctBsEBM/review">
            <ButtonPrimary
              content="Click here share your feedback"
              icon={<IoIosSend />}
              className="px-5 w-100 w-lg-75 px-5 p-0 py-2 py-lg-2"
            />
          </Link>
        </div>
      </div>
    </Container>
  );
};

export default Testimonials;
