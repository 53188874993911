import React, { useState } from "react";
import "./Navbar.scss";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { call, logo, mail } from "../../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import ButtonSecondary from "../../common/ButtonSecondary/ButtonSecondary";
import { LuPhoneCall } from "react-icons/lu";

const CustomNavbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      <div className="topbar d-flex justify-content-between flex-lg-row flex-column">
        <div className="d-flex align-items-center justify-content-center ">
          <img loading="lazy"src={mail} alt="Mail_Icon" className="me-2 img-fluid" />
          <p>info@khalsapackersmovers.com</p>
        </div>
        <Link to="tel://8541893616" className="text-decoration-none">
          <div className="mobileNumber d-flex align-items-center justify-content-center mt-2 mt-lg-0  ">
            <img loading="lazy"src={call} alt="Call_Icon" className="me-2 img-fluid" />
            <p className="text-decoration-none">+91 8541893616</p>
          </div>
        </Link>
      </div>
      <Navbar expand="lg">
        <NavbarBrand href="/" className="me-0 p-0">
          <img loading="lazy"src={logo} alt="Company_Logo" className="custom-width" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto ms-auto" navbar>
            <NavItem>
              <NavLink href="/">Home</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/about-us">About Us</NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Services
              </DropdownToggle>
              <DropdownMenu center="true">
                <DropdownItem
                  onClick={() => navigate("/services/international-relocation")}
                >
                  International Relocation
                </DropdownItem>
                <DropdownItem
                  onClick={() => navigate("/services/transportation-services")}
                >
                  Transportation Services
                </DropdownItem>
                <DropdownItem
                  onClick={() => navigate("/services/warehousing-and-storage")}
                >
                  Warehousing and Storage
                </DropdownItem>
                <DropdownItem
                  onClick={() => navigate("/services/loading-and-unloading")}
                >
                  Loading and Unloading
                </DropdownItem>
                <DropdownItem
                  onClick={() => navigate("/services/office-relocation")}
                >
                  Office Relocation
                </DropdownItem>
                <DropdownItem
                  onClick={() => navigate("/services/car-transportation")}
                >
                  Car Transportation
                </DropdownItem>
                <DropdownItem
                  onClick={() => navigate("/services/bike-transportation")}
                >
                  Bike Transportation
                </DropdownItem>
                <DropdownItem
                  onClick={() => navigate("/services/intercity-moves")}
                >
                  Intercity Moves
                </DropdownItem>
                <DropdownItem
                  onClick={() => navigate("/services/transit-insurance")}
                >
                  Transit Insurance
                </DropdownItem>
                <DropdownItem
                  onClick={() => navigate("/services/heavy-machinery-shifting")}
                >
                  Heavy Machinery Shifting
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <NavLink href="/city-wise">City Wise</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/faqs">FAQS</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/clients">Clients</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/contact-us">Contact Us</NavLink>
            </NavItem>
          </Nav>
          <a
            href="tel://8541893616"
            className="text-white text-decoration-none"
          >
            <ButtonSecondary content="Call Us" icon={<LuPhoneCall />} />
          </a>
          {/* <Button
            color="dark"
            className="d-flex justify-content-center align-items-center callUs"
          >
            <a
              href="tel://8541893616"
              className="text-white text-decoration-none"
            >
              <img
                src={call_white}
                alt="Call_Icon_White"
                className="me-2 call-icon"
              />
              Call Us
            </a>
          </Button> */}
        </Collapse>
      </Navbar>
    </>
  );
};

export default CustomNavbar;
