import React from "react";
import { Col, Container, Row } from "reactstrap";
import "./AboutUs.scss";
import ButtonPrimary from "../../common/ButtonPrimary/ButtonPrimary";
import { green_tick } from "../../../assets/images";
import { IoIosArrowDropright } from "react-icons/io";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const AboutUs = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="mt-5 about_us p-3 p-lg-5">
      <Container fluid>
        <Row className="m-0">
          <Col lg={6}>
            <p className="welcome">Welcome to</p>
            <p className="title mt-2">Khalsa Packers & Movers Pvt. Ltd.</p>
            <p className="description mt-5">
              Welcome to our Company - Khalsa Packers & Movers, as a leading
              packers and movers in Bhagalpur, we are offering smooth and
              world-class packing and moving services from Bhagalpur according
              to your all relocation needs.Our commitment to customer
              satisfaction sets apart. We exceed your expectations at every step
              of the process offering personalized services and attention to
              details you won't find anywhere else in and from Bhagalpur at a
              very reasonable price.{" "}
            </p>
            <Link to="/about-us">
              <ButtonPrimary
                content="Read More"
                icon={<IoIosArrowDropright size="22" />}
                className="mt-5 px-lg-4 px-4 py-2 py-lg-2"
              />
            </Link>
          </Col>
          <Col lg={6} className="mt-5 mt-lg-0">
            <Slider {...settings}>
              <div>
                <video
                  src="https://res.cloudinary.com/dgdl2xkcl/video/upload/f_auto:video,q_auto/v1/Khalsa_Testimonials/rxfp4sn1rvis9ra2g4xw"
                  width="100%"
                  height="80%"
                  controls
                  className="rounded"
                ></video>
              </div>
              <div>
                <video
                  src="https://res.cloudinary.com/dgdl2xkcl/video/upload/f_auto:video,q_auto/v1/Khalsa_Testimonials/f3gwslkurm2vn1g3ttwl"
                  width="100%"
                  height="80%"
                  controls
                ></video>
              </div>
            </Slider>
          </Col>

          <div className="d-flex justify-content-between flex-column flex-lg-row mt-5 mt-lg-0">
            <div className="features d-flex justify-content-center align-items-center">
              <img loading="lazy"src={green_tick} alt="Green_Tick" />
              <p>Trained & Experienced Staff</p>
            </div>
            <div className="features d-flex justify-content-center align-items-center">
              <img loading="lazy"src={green_tick} alt="Green_Tick" />

              <p>100% Client Satisfaction</p>
            </div>{" "}
            <div className="features d-flex justify-content-center align-items-center">
              <img loading="lazy"src={green_tick} alt="Green_Tick" />

              <p>25 Years of great Reputation</p>
            </div>
            <div className="features d-flex justify-content-center align-items-center">
              <img loading="lazy"src={green_tick} alt="Green_Tick" />
              <p>Full Support</p>
            </div>{" "}
            <div className="features d-flex justify-content-center align-items-center">
              <img loading="lazy"src={green_tick} alt="Green_Tick" />
              <p>Affordable Price</p>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default AboutUs;
