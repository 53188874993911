import React from "react";
import "./index.scss";
import CustomBreadcrumb from "../../components/common/Breadcrumb/Custom_Breadcrumb";
import { Col, Row } from "reactstrap";
import { call, intercity } from "../../assets/images";
import { Link } from "react-router-dom";

const IntercityMoves = () => {
  return (
    <section className="services">
      <CustomBreadcrumb name="Our Services" secondName="Intercity Moves" />
      <Row className="m-0 mt-5 p-3 p-lg-5">
        <Col lg="8">
          <img loading="lazy"src={intercity} alt="Intercity_Movies" className="w-100" />
          <p className="service_name mt-5">Intercity Moves</p>
          <p className="service_description mt-3">
            Welcome to our specialized intercity moves service, where we excel
            in providing reliable and hassle-free solutions for moving
            individuals and families between cities. With our experienced team
            and commitment to excellence, we ensure that your intercity move is
            executed with precision, care, and efficiency.
          </p>
        </Col>
        <Col lg="4">
          <div className="our-services p-3 p-lg-5">
            <p className="header">Our Services</p>
            <ul>
              <li>
                <Link to="/services/international-relocation">
                  International Relocation
                </Link>
              </li>
              <li>
                <Link to="/services/transportation-services">
                  Transportation Services
                </Link>
              </li>
              <li>
                <Link to="/services/warehousing-and-storage">
                  Warehousing and Storage
                </Link>
              </li>
              <li>
                <Link to="/services/loading-and-unloading">
                  Loading and Unloading
                </Link>
              </li>
              <li>
                <Link to="/services/office-relocation">Office Relocation</Link>
              </li>
              <li>
                <Link to="/services/car-transportation">
                  Car Transportation
                </Link>
              </li>
              <li>
                <Link to="/services/bike-transportation">
                  Bike Transportation
                </Link>
              </li>
              <li>
                <Link to="/services/intercity-moves">Intercity Moves</Link>
              </li>
              <li>
                <Link to="/services/transit-insurance">Transit Insurance</Link>
              </li>
              <li>
                <Link to="/services/heavy-machinery-shifting">
                  Heavy Machinery Shifting
                </Link>
              </li>
            </ul>
          </div>
        </Col>
        <Col lg="12">
          <p className="sub-head">Why Choose Us for Your Intercity Move?</p>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Experience and Expertise</p>
            <p className="content">
              Our team has extensive experience in handling intercity moves,
              navigating the unique challenges and logistics involved in moving
              between cities. Whether you're relocating for work, family, or
              personal reasons, we have the knowledge and expertise to make your
              move a success.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Comprehensive Services</p>
            <p className="content">
              From packing and loading to transportation and unpacking, we offer
              a comprehensive range of services to meet all your intercity
              moving needs. Whether you're moving a small apartment or a large
              family home, we have the resources and capabilities to handle it
              all.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Personalized Approach</p>
            <p className="content">
              We understand that every move is different, which is why we take a
              personalized approach to each relocation. Our team will work
              closely with you to understand your specific needs and
              preferences, developing a customized moving plan that meets your
              requirements and budget.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Efficiency and Reliability</p>
            <p className="content">
              We prioritize reliability and efficiency in everything we do. Our
              team works quickly and efficiently to ensure that your belongings
              are transported safely and securely to your new destination,
              minimizing downtime and disruptions to your life.
            </p>
          </div>
        </Col>
        <Col lg="12">
          <p className="sub-head">Our Intercity Moving Process</p>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Pre-Move Consultation</p>
            <p className="content">
              We begin by conducting a thorough consultation to understand your
              moving needs and requirements. Based on this consultation, we
              develop a customized moving plan that outlines key milestones,
              tasks, and timelines for your move.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Packing and Preparation</p>
            <p className="content">
              Our team will carefully pack and prepare all of your belongings
              for transport, using high-quality packing materials and techniques
              to ensure their safety and security during transit.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Transportation</p>
            <p className="content">
              We use modern, well-maintained vehicles equipped with GPS tracking
              and communication systems to transport your belongings between
              cities. Our drivers are experienced in navigating intercity routes
              and traffic conditions, ensuring timely delivery to your new
              destination.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Unpacking and Setup</p>
            <p className="content">
              Upon arrival at your new home, our team will unpack and set up all
              of your belongings according to your specifications. Whether it's
              furniture assembly, appliance installation, or arranging items in
              your new space, we'll ensure that everything is in place and ready
              for you to settle in.
            </p>
          </div>
        </Col>
        <Col lg="12">
          <p className="content text-black mt-4">
            At KHALSA PACKERS AND MOVERS PVT.LTD., we're committed to providing
            top-quality intercity moves that you can trust. Whether you're
            moving across the state or across the country, you can rely on us to
            handle your intercity move with professionalism, care, and attention
            to detail.
          </p>
          <Link to="/#enquiryForm" className="text-black text-decoration-none">
            <div className="button-conclusion d-flex justify-content-center mt-5 align-items-center">
              <img loading="lazy"src={call} alt="Call" className="me-4" />
              <p className="text-black text-decoration-none">
                Contact us today to learn more about our intercity moving
                services and how we can help you with your next relocation.
              </p>
            </div>
          </Link>
        </Col>
      </Row>
    </section>
  );
};

export default IntercityMoves;
