import React from "react";
import "./index.scss";
import CustomBreadcrumb from "../../components/common/Breadcrumb/Custom_Breadcrumb";
import { Col, Row } from "reactstrap";
import { call, carTransportation } from "../../assets/images";
import { Link } from "react-router-dom";

const CarTranportation = () => {
  return (
    <section className="services">
      <CustomBreadcrumb name="Our Services" secondName="Car Transportation" />
      <Row className="m-0 mt-5 p-3 p-lg-5">
        <Col lg="8">
          <img loading="lazy"src={carTransportation} alt="Car_Transport" className="w-100" />
          <p className="service_name mt-5">Car Transportation</p>
          <p className="service_description mt-3">
            Welcome to our specialized car transportation services, where we
            ensure the safe and secure transport of your vehicles to their
            destination. Whether you're moving across the country or need to
            transport your vehicle for any reason, we provide reliable and
            efficient solutions tailored to your needs.
          </p>
        </Col>
        <Col lg="4">
          <div className="our-services p-3 p-lg-5">
            <p className="header">Our Services</p>
            <ul>
              <li>
                <Link to="/services/international-relocation">
                  International Relocation
                </Link>
              </li>
              <li>
                <Link to="/services/transportation-services">
                  Transportation Services
                </Link>
              </li>
              <li>
                <Link to="/services/warehousing-and-storage">
                  Warehousing and Storage
                </Link>
              </li>
              <li>
                <Link to="/services/loading-and-unloading">
                  Loading and Unloading
                </Link>
              </li>
              <li>
                <Link to="/services/office-relocation">Office Relocation</Link>
              </li>
              <li>
                <Link to="/services/car-transportation">
                  Car Transportation
                </Link>
              </li>
              <li>
                <Link to="/services/bike-transportation">
                  Bike Transportation
                </Link>
              </li>
              <li>
                <Link to="/services/intercity-moves">Intercity Moves</Link>
              </li>
              <li>
                <Link to="/services/transit-insurance">Transit Insurance</Link>
              </li>
              <li>
                <Link to="/services/heavy-machinery-shifting">
                  Heavy Machinery Shifting
                </Link>
              </li>
            </ul>
          </div>
        </Col>
        <Col lg="12">
          <p className="sub-head">Why Choose Us for Your Car Transportation</p>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Expertise and Experience</p>
            <p className="content">
              With years of experience in the transportation industry, our team
              has the expertise to handle all types of vehicles, from cars and
              trucks to motorcycles and specialty vehicles. We understand the
              intricacies of vehicle transportation and ensure that your vehicle
              arrives safely and on time.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Comprehensive Services</p>
            <p className="content">
              We offer a range of car transportation services to meet your
              needs, including door-to-door delivery, open and enclosed
              transport options, and vehicle tracking for added peace of mind.
              Whether you're moving a single vehicle or an entire fleet, we have
              the resources and capabilities to handle it all.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Safety and Security</p>
            <p className="content">
              The safety and security of your vehicle are our top priorities.
              Our team uses state-of-the-art equipment and techniques to secure
              your vehicle during transport, minimizing the risk of damage or
              theft. With our comprehensive insurance coverage, you can have
              peace of mind knowing that your vehicle is protected throughout
              the transportation process.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Efficiency and Reliability</p>
            <p className="content">
              We understand the importance of timely delivery, which is why we
              prioritize efficiency and reliability in everything we do. Our
              team works diligently to ensure that your vehicle is transported
              to its destination on time and in pristine condition, allowing you
              to focus on other aspects of your move.
            </p>
          </div>
        </Col>
        <Col lg="12">
          <p className="sub-head">Our Car Transportation Process</p>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Preparation</p>
            <p className="content">
              Our team will work with you to assess your car transportation
              needs and develop a customized plan for the move. We'll discuss
              factors such as pick-up and delivery locations, transport options,
              and any special instructions for handling your vehicle.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Loading and Transport</p>
            <p className="content">
              We use specialized equipment and techniques to load your vehicle
              onto our transport vehicle safely and securely. Depending on your
              preferences and budget, we offer both open and enclosed transport
              options to protect your vehicle from the elements during transit.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Tracking and Communication</p>
            <p className="content">
              Throughout the transportation process, we provide regular updates
              on the status of your vehicle and communicate with you every step
              of the way. Our team is available to answer any questions or
              address any concerns you may have, ensuring a smooth and
              stress-free experience.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Delivery and Inspection</p>
            <p className="content">
              Upon arrival at the destination, our team will carefully unload
              your vehicle and conduct a thorough inspection to ensure that it
              has arrived in the same condition as when it was loaded. We'll
              coordinate delivery according to your schedule and preferences,
              ensuring a seamless end-to-end experience.
            </p>
          </div>
        </Col>
        <Col lg="12">
          <p className="content text-black mt-4">
            At KHALSA PACKERS AND MOVERS PVT LTD, we're committed to providing
            top-quality car transportation services that you can trust. Whether
            you're moving across the country or need to transport your vehicle
            for any reason, you can rely on us to handle your car transportation
            needs with professionalism, care, and attention to detail.
          </p>
          <Link to="/#enquiryForm" className="text-black text-decoration-none">
            <div className="button-conclusion d-flex justify-content-center mt-5 align-items-center">
              <img loading="lazy"src={call} alt="Call" className="me-4" />
              <p className="text-black text-decoration-none">
                Contact us today to learn more about our car transportation
                services and how we can help you with your vehicle relocation
                needs.
              </p>
            </div>
          </Link>
        </Col>
      </Row>
    </section>
  );
};

export default CarTranportation;
