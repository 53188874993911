import React, { useState } from "react";
import "./CitywiseEnquiry.scss";
import { FormGroup, Label, Input } from "reactstrap";
import TextArea from "antd/es/input/TextArea";
import ButtonPrimary from "../../common/ButtonPrimary/ButtonPrimary";
import { LuPhoneCall } from "react-icons/lu";

const CitywiseEnquiry = ({ name }) => {
  const [fromCity, setFromCity] = useState("");
  const [toCity, setToCity] = useState("");
  const [personalDetails, setPersonalDetails] = useState({
    name: "",
    mobile: "",
    email: "",
    dob: "",
    enquiry: "",
  });
  const handleSubmit = () => {
    console.log("From City:", fromCity);
    console.log("To City:", toCity);
    console.log("Personal Details:", personalDetails);
    const requestData = {
      fromCity: fromCity,
      toCity: toCity,
      yourName: personalDetails.name,
      mobileNo: personalDetails.mobile,
      email: personalDetails.email,
      dob: personalDetails.dob,
      enquiry: personalDetails.enquiry,
    };
    // Here you would typically handle backend submission via an API call
    fetch("https://aestraswift.ocpl.tech/api2/form/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        alert("Thank you for your query!");
        // Reset form fields after successful submission
        setFromCity("");
        setToCity("");
        setPersonalDetails({
          name: "",
          mobile: "",
          email: "",
          dob: "",
          enquiry: "",
        });
      })
      .then((data) => {
        console.log("API Response:", data);
        // Handle successful response here
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle error here
      });
  };

  return (
    <section className="">
      <div className="citywise-form p-4 p-lg-3  ">
        <p className="header">
          {" "}
          For Packers And Movers
          <p className="blinking">
            {" "}
            <LuPhoneCall className="me-3" size="23" />
            +91-8541893616
          </p>
          Enquire Now
        </p>
        <div className="outer-box mt-5 ">
          <FormGroup floating className=" mt-1 mt-lg-2">
            <Input
              id="fromCity"
              size="large"
              name="fromCity"
              placeholder="From City"
              type="text"
              value={fromCity}
              onChange={(e) => setFromCity(e.target.value)}
            />
            <Label>From City</Label>
          </FormGroup>
          <FormGroup floating>
            <Input
              id="toCity"
              name="toCity"
              size="large"
              placeholder="To City"
              type="text"
              value={toCity}
              onChange={(e) => setToCity(e.target.value)}
            />
            <Label>To City</Label>
          </FormGroup>
          <FormGroup floating>
            <Input
              id="name"
              size="large"
              name="name"
              placeholder="Your Name"
              type="text"
              value={personalDetails.name}
              onChange={(e) =>
                setPersonalDetails({
                  ...personalDetails,
                  name: e.target.value,
                })
              }
            />
            <Label>Your Name</Label>
          </FormGroup>
          <FormGroup floating>
            <Input
              id="mobile"
              size="large"
              name="mobile"
              placeholder="Mobile Number"
              type="text"
              value={personalDetails.mobile}
              onChange={(e) =>
                setPersonalDetails({
                  ...personalDetails,
                  mobile: e.target.value,
                })
              }
            />
            <Label>Mobile Number</Label>
          </FormGroup>
          <FormGroup floating>
            <Input
              id="email"
              size="large"
              name="email"
              placeholder="Email Id"
              type="email"
              value={personalDetails.email}
              onChange={(e) =>
                setPersonalDetails({
                  ...personalDetails,
                  email: e.target.value,
                })
              }
            />
            <Label>Email</Label>
          </FormGroup>
          <FormGroup floating>
            <Input
              id="dob"
              size="large"
              name="dob"
              placeholder="dd/mm/yyyy"
              type="date"
              value={personalDetails.dob}
              onChange={(e) =>
                setPersonalDetails({
                  ...personalDetails,
                  dob: e.target.value,
                })
              }
            />
            <Label>Date of Birth</Label>
          </FormGroup>
          <FormGroup floating>
            <TextArea
              rows={4}
              id="enquiry"
              size="large"
              name="enquiry"
              placeholder="Your Enquiry"
              type="text"
              value={personalDetails.enquiry}
              onChange={(e) =>
                setPersonalDetails({
                  ...personalDetails,
                  enquiry: e.target.value,
                })
              }
            />
            {/* <Label>Your Enquiry</Label> */}
          </FormGroup>
          {/* 
          <ButtonPrimary
            content="Back"
            className="px-4 py-0 h6"
            onClick={() => setCurrentStep(1)}
          /> */}
          <div className="text-center">
            <ButtonPrimary
              content="Submit"
              className="px-4 py-1 h6"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CitywiseEnquiry;
