import React from "react";
import "./CallButton.scss";
import { Link } from "react-router-dom";

const CallButton = () => {
  return (
    <section className="call_button fix-icon-item" id="fix-icon">
      <Link to="tel://8541893616">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 39 39"
          fill="none"
        >
          <path
            d="M2.22532 5.99339C4.05837 22.1323 16.8677 34.9416 33.0066 36.7747C35.2016 37.024 37 35.2091 37 33V24.875C37 24.3227 36.552 23.8774 36.0003 23.8526C33.5414 23.742 31.1882 23.2254 29.0061 22.3683L25.6826 25.6918C20.2772 23.105 15.895 18.7228 13.3083 13.3174L16.6317 9.99397C15.7746 7.81186 15.258 5.45863 15.1474 2.99972C15.1226 2.448 14.6773 2 14.125 2H6C3.79086 2 1.97601 3.79836 2.22532 5.99339Z"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </Link>
    </section>
  );
};

export default CallButton;
