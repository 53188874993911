import React, { useState } from "react";
import CustomBreadcrumb from "../../components/common/Breadcrumb/Custom_Breadcrumb";
import "./Tracking.scss";
import { Input } from "antd";
import { Button } from "reactstrap";

const Tracking = () => {
  const [shipmentNo, setShipmentNo] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [error, setError] = useState("");

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form inputs
    if (!shipmentNo.trim() || !mobileNumber.trim()) {
      setError("Please fill out all fields.");
      return;
    }

    // Clear any previous error message
    setError("");

    // Log form values to the console
    console.log("Shipment No.: ", shipmentNo);
    console.log("Mobile Number: ", mobileNumber);

    // Reset form inputs
    setShipmentNo("");
    setMobileNumber("");
  };
  return (
    <section className="tracking">
      <CustomBreadcrumb name="Tracking" />
      <div className="outer-box">
        <h1>TRACK YOUR SHIPMENT</h1>
        <div className="inner-box">
          <form onSubmit={handleSubmit}>
            <Input
              size="large"
              className="my-3"
              placeholder="Shipment No./ Tracking ID"
              value={shipmentNo}
              onChange={(e) => setShipmentNo(e.target.value)}
            />
            <Input
              size="large"
              className="my-3"
              placeholder="Mobile Number"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
            {error && <div className="error-message">{error}</div>}
            <Button color="warning" className="my-3" type="submit">
              Submit
            </Button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Tracking;
