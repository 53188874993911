import React from "react";
import "./index.scss";
import CustomBreadcrumb from "../../components/common/Breadcrumb/Custom_Breadcrumb";
import { Col, Row } from "reactstrap";
import { call, heavyMachine } from "../../assets/images";
import { Link } from "react-router-dom";

const HeavyMachineLifting = () => {
  return (
    <section className="services">
      <CustomBreadcrumb
        name="Our Services"
        secondName="Heavy Machine Lifting"
      />
      <Row className="m-0 mt-5 p-3 p-lg-5">
        <Col lg="8">
          <img loading="lazy"src={heavyMachine} alt="Heavy_Machine" className="w-100" />
          <p className="service_name mt-5">Heavy Machine Lifting</p>
          <p className="service_description mt-3">
            Welcome to our specialized heavy machinery shifting services, where
            we excel in providing efficient and reliable solutions for moving
            heavy equipment and machinery. With our team of experienced
            professionals and specialized equipment, we're here to ensure that
            your heavy machinery relocation is executed with precision and care.
          </p>
        </Col>
        <Col lg="4">
          <div className="our-services p-3 p-lg-5">
            <p className="header">Our Services</p>
            <ul>
              <li>
                <Link to="/services/international-relocation">
                  International Relocation
                </Link>
              </li>
              <li>
                <Link to="/services/transportation-services">
                  Transportation Services
                </Link>
              </li>
              <li>
                <Link to="/services/warehousing-and-storage">
                  Warehousing and Storage
                </Link>
              </li>
              <li>
                <Link to="/services/loading-and-unloading">
                  Loading and Unloading
                </Link>
              </li>
              <li>
                <Link to="/services/office-relocation">Office Relocation</Link>
              </li>
              <li>
                <Link to="/services/car-transportation">
                  Car Transportation
                </Link>
              </li>
              <li>
                <Link to="/services/bike-transportation">
                  Bike Transportation
                </Link>
              </li>
              <li>
                <Link to="/services/intercity-moves">Intercity Moves</Link>
              </li>
              <li>
                <Link to="/services/transit-insurance">Transit Insurance</Link>
              </li>
              <li>
                <Link to="/services/heavy-machinery-shifting">
                  Heavy Machinery Shifting
                </Link>
              </li>
            </ul>
          </div>
        </Col>
        <Col lg="12">
          <p className="sub-head">
            Why Choose Us for Your Heavy Machinery Shifting?
          </p>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Specialized Expertise</p>
            <p className="content">
              Moving heavy machinery requires specialized knowledge and
              expertise. Our team of movers is trained and experienced in
              handling all types of heavy equipment, from construction machinery
              to industrial tools, ensuring that your machinery is transported
              safely and securely.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Customized Solutions</p>
            <p className="content">
              We understand that every heavy machinery relocation is unique,
              which is why we offer customized solutions tailored to your
              specific needs and requirements. Whether you're moving a single
              piece of equipment or an entire production line, we'll work
              closely with you to develop a customized plan that meets your
              needs and minimizes downtime
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Safety First Approach</p>
            <p className="content">
              The safety of your machinery, as well as our team and bystanders,
              is our top priority. We adhere to strict safety protocols and use
              specialized equipment and techniques to ensure that your heavy
              machinery is transported safely and securely, minimizing the risk
              of damage or injury.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Efficiency and Reliability</p>
            <p className="content">
              We understand the importance of minimizing downtime and
              disruptions to your operations. Our team works quickly and
              efficiently to ensure that your heavy machinery is relocated on
              time and within budget, allowing you to resume normal operations
              as soon as possible.
            </p>
          </div>
        </Col>
        <Col lg="12">
          <p className="sub-head">Our Heavy Machinery Shifting Process</p>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Pre-Move Assessment</p>
            <p className="content">
              Our team will conduct a thorough assessment of your heavy
              machinery and the logistics of the relocation, taking into account
              factors such as size, weight, and complexity. Based on this
              assessment, we'll develop a customized plan for the relocation,
              outlining key milestones and deadlines.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Equipment Preparation</p>
            <p className="content">
              Prior to the move, our team will ensure that your heavy machinery
              is properly prepared for transportation. This may include
              disassembly, securing loose components, and applying protective
              coverings to prevent damage during transit.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Transportation</p>
            <p className="content">
              We use specialized trucks and trailers equipped with hydraulic
              lift systems and tie-downs to transport your heavy machinery
              safely and securely. Our drivers are experienced in navigating
              challenging terrain and traffic conditions to ensure a smooth and
              efficient transport process.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">On-Site Installation</p>
            <p className="content">
              Upon arrival at the destination, our team will coordinate the
              unloading and installation of your heavy machinery, ensuring that
              it is positioned and secured according to your specifications.
              We'll also conduct a final inspection to ensure that everything is
              in place and functioning properly before handing over the reins to
              you.
            </p>
          </div>
        </Col>
        <Col lg="12">
          <p className="content text-black mt-4">
            At KHALSA PACKERS AND MOVERS PVT LTD we're committed to providing
            top-quality heavy machinery shifting services that you can trust.
            Whether you're moving a single piece of equipment or an entire
            production facility, you can rely on us to handle your heavy
            machinery relocation with professionalism, precision, and care.
          </p>
          <Link to="/#enquiryForm" className="text-black text-decoration-none">
            <div className="button-conclusion d-flex justify-content-center mt-5 align-items-center">
              <img loading="lazy"src={call} alt="Call" className="me-4" />
              <p className="text-black text-decoration-none">
                Contact us today to learn more about our heavy machinery
                shifting services and how we can help you with
              </p>
            </div>
          </Link>
        </Col>
      </Row>
    </section>
  );
};

export default HeavyMachineLifting;
