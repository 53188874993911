import React from "react";
import "./Citywise.scss";
import CustomBreadcrumb from "../../components/common/Breadcrumb/Custom_Breadcrumb";
import { Container } from "reactstrap";
import { CiLocationOn } from "react-icons/ci";
import { Link } from "react-router-dom";

const Citywise = () => {
  const cityName = [
    {
      name: "Packers and Movers in Bhagalpur",
      link: "/city-wise/packers-movers-in-bhagalpur",
    },
    {
      name: "Packers and Movers in Munger",
      link: "/city-wise/packers-movers-in-munger",
    },
    {
      name: "Packers and Movers in Jamalpur",
      link: "/city-wise/packers-movers-in-jamalpur",
    },
    {
      name: "Packers and Movers in Banka",
      link: "/city-wise/packers-movers-in-banka",
    },
    {
      name: "Packers and Movers in Godda",
      link: "/city-wise/packers-movers-in-godda",
    },
    {
      name: "Packers and Movers in Khagaria",
      link: "/city-wise/packers-movers-in-khagaria",
    },
    {
      name: "Packers and Movers in Purnia",
      link: "/city-wise/packers-movers-in-purnia",
    },
    {
      name: "Packers and Movers in Katihar",
      link: "/city-wise/packers-movers-in-katihar",
    },
    {
      name: "Packers and Movers in Dumka",
      link: "/city-wise/packers-movers-in-dumka",
    },
    {
      name: "Packers and Movers in Araria",
      link: "/city-wise/packers-movers-in-araria",
    },
    {
      name: "Packers and Movers in Patna",
      link: "/city-wise/packers-movers-in-patna",
    },
    {
      name: "Packers and Movers in Begusarai",
      link: "/city-wise/packers-movers-in-begusarai",
    },
    {
      name: "Packers and Movers in Forbisganj",
      link: "/city-wise/packers-movers-in-forbisganj",
    },
    {
      name: "Packers and Movers in Darbhanga",
      link: "/city-wise/packers-movers-in-darbhanga",
    },
    {
      name: "Packers and Movers in Samastipur",
      link: "/city-wise/packers-movers-in-samastipur",
    },
    {
      name: "Packer and Movers in Muzaffarpur",
      link: "/city-wise/packers-movers-in-muzaffarpur",
    },
    {
      name: "Packers and Movers in Bojpur",
      link: "/city-wise/packers-movers-in-bojpur",
    },
    {
      name: "Packers and Movers in Barouni",
      link: "/city-wise/packers-movers-in-barouni",
    },
    {
      name: "Packers and Movers in Nawada",
      link: "/city-wise/packers-movers-in-nawada",
    },
    {
      name: "Packers and Movers in Sultanganj",
      link: "/city-wise/packers-movers-in-sultanganj",
    },
    {
      name: "Packers and Movers in Madhubani",
      link: "/city-wise/packers-movers-in-madhubani",
    },
    {
      name: "Packers and Movers in Betiha",
      link: "/city-wise/packers-movers-in-betiha",
    },
  ];
  return (
    <section className="city-wise">
      <CustomBreadcrumb name="Citywise" />
      <Container className="my-5">
        <div className="row">
          {cityName.map((item, index) => {
            return (
              <div className="col-lg-4 col-md-6">
                <Link
                  to={item.link}
                  className="text-decoration-none text-black"
                >
                  <div className="box d-flex align-items-center " key={index}>
                    <div className="icons">
                      <CiLocationOn size="34" />
                    </div>
                    <div className="p-2">{item.name}</div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default Citywise;
