import React from "react";
import CustomBreadcrumb from "../../components/common/Breadcrumb/Custom_Breadcrumb";
import { Col, Row } from "reactstrap";
import "./ContactUs.scss";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
import { IoIosGlobe } from "react-icons/io";
import { IoCallOutline } from "react-icons/io5";
const ContactUs = () => {
  return (
    <section className="contact-us">
      <CustomBreadcrumb name="Contact Us" />
      <Row className="m-0 p-0 p-lg-5 mt-5">
        <Col lg="6" className="p-5">
          <p className="heading">Contact Us</p>
          <p className="sub-head">
            Feel free to contact us, we are here to help you.
          </p>
          <div className="p-1 p-lg-3 mt-5 d-flex contact_info   ">
            <IoCallOutline size="33" />
            <div className="content ms-0  ms-lg-5 ms-md-5">
              <p className="title">Phone</p>
              <p className="data">+91 8541893616, +91 6200086395</p>
            </div>
          </div>
          <div className="p-1 p-lg-3 mt-1 d-flex contact_info  ">
            <FaWhatsapp size="33" />
            <div className="content ms-0  ms-lg-5 ms-md-5">
              <p className="title">Whatsapp</p>
              <p className="data">+91 8541893616</p>
            </div>
          </div>
          <div className="p-1 p-lg-3 mt-1 d-flex contact_info  ">
            <MdOutlineEmail size="33" />
            <div className="content ms-0  ms-lg-5 ms-md-5">
              <p className="title">Email</p>
              <p className="data">info@khalsapackersmovers.com </p>
            </div>
          </div>
          <div className="p-1 p-lg-3 mt-1 d-flex contact_info  ">
            <CiLocationOn size="33" />
            <div className="content ms-0  ms-lg-5 ms-md-5">
              <p className="title">Office Address</p>
              <p className="data">
                Trimurti Chowk, GC Banerjee Road, Near <br />
                Pushpanjali Sweets, Bhikhanpur, Bhagalpur,
                <br /> Bihar 812001{" "}
              </p>
            </div>
          </div>
          <div className="p-1 p-lg-3 mt-1 d-flex contact_info  ">
            <IoIosGlobe size="33" />
            <div className="content ms-0  ms-lg-5 ms-md-5">
              <p className="title">Website</p>
              <p className="data">www.khalsapackersmovers.com</p>
            </div>
          </div>
        </Col>
        <Col lg="6" className="p-5">
          <p className="heading">Our Location</p>
          <p className="sub-head">Get In Touch Our Location.</p>
          <div className="location mt-5 p-3 aspect-ratio aspect-ratio-16by9">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d115478.58667970837!2d86.83509429726563!3d25.246831099999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f03766f501db3d%3A0x6cd0724e308e124e!2sKHALSA%20PACKERS%20%26%20MOVERS%20(TRANSPORT)%20PVT.LTD.!5e0!3m2!1sen!2sin!4v1713854514103!5m2!1sen!2sin"
              allowfullscreen=""
              loading="lazy"
              title="Location"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default ContactUs;
