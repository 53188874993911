import React from "react";
import "./index.scss";
import CustomBreadcrumb from "../../components/common/Breadcrumb/Custom_Breadcrumb";
import { Col, Row } from "reactstrap";
import { call, warehouse } from "../../assets/images";
import { Link } from "react-router-dom";

const WarehouseAndStorage = () => {
  return (
    <section className="services">
      <CustomBreadcrumb
        name="Our Services"
        secondName="Warehousing And Storage"
      />
      <Row className="m-0 mt-5 p-3 p-lg-5">
        <Col lg="8">
          <img loading="lazy"src={warehouse} alt="Warehouse_AND_Storage" className="w-100" />
          <p className="service_name mt-5">Warehousing And Storage</p>
          <p className="service_description mt-3">
            Welcome to our warehousing and storage services, where we provide
            safe and reliable storage solutions for your belongings during the
            moving process. Whether you need short-term storage between moves or
            long-term warehousing for excess inventory, we're here to meet your
            needs with professionalism and care.
          </p>
        </Col>
        <Col lg="4">
          <div className="our-services p-3 p-lg-5">
            <p className="header">Our Services</p>
            <ul>
              <li>
                <Link to="/services/international-relocation">
                  International Relocation
                </Link>
              </li>
              <li>
                <Link to="/services/transportation-services">
                  Transportation Services
                </Link>
              </li>
              <li>
                <Link to="/services/warehousing-and-storage">
                  Warehousing and Storage
                </Link>
              </li>
              <li>
                <Link to="/services/loading-and-unloading">
                  Loading and Unloading
                </Link>
              </li>
              <li>
                <Link to="/services/office-relocation">Office Relocation</Link>
              </li>
              <li>
                <Link to="/services/car-transportation">
                  Car Transportation
                </Link>
              </li>
              <li>
                <Link to="/services/bike-transportation">
                  Bike Transportation
                </Link>
              </li>
              <li>
                <Link to="/services/intercity-moves">Intercity Moves</Link>
              </li>
              <li>
                <Link to="/services/transit-insurance">Transit Insurance</Link>
              </li>
              <li>
                <Link to="/services/heavy-machinery-shifting">
                  Heavy Machinery Shifting
                </Link>
              </li>
            </ul>
          </div>
        </Col>
        <Col lg="12">
          <p className="sub-head">
            Why Choose Us for Your Warehousing and Storage Needs?
          </p>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Secure Facilities</p>
            <p className="content">
              Our warehouses are equipped with state-of-the-art security
              systems, including surveillance cameras, access controls, and
              alarm systems, to ensure the safety and security of your
              belongings at all times.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Climate Control</p>
            <p className="content">
              We offer climate-controlled storage options to protect your items
              from temperature fluctuations, humidity, and other environmental
              factors that can cause damage or deterioration over time. From
              delicate antiques to sensitive electronics, your belongings are in
              good hands with us.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Flexible Storage Solutions</p>
            <p className="content">
              Whether you need short-term storage while in between moves or
              long-term warehousing for excess inventory, we offer flexible
              storage solutions to meet your needs. Our facilities are equipped
              to accommodate items of all sizes, from small boxes to large
              furniture and equipment.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Convenience</p>
            <p className="content">
              Our convenient location and accessible storage facilities make it
              easy for you to store and retrieve your belongings whenever you
              need them. With flexible access hours and responsive customer
              service, we're here to make your storage experience as hassle-free
              as possible.
            </p>
          </div>
        </Col>
        <Col lg="12">
          <p className="sub-head">Our Warehousing and Storage Services </p>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Inventory Management</p>
            <p className="content">
              We offer comprehensive inventory management services to keep track
              of your belongings while they're in storage. Our team will
              carefully document and label each item, making it easy for you to
              keep track of what you have stored and retrieve items as needed.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Packing and Loading</p>
            <p className="content">
              If you need assistance with packing and loading your belongings
              for storage, we're here to help. Our professional movers will
              carefully pack and prepare your items for storage, ensuring that
              they're properly protected and secured during transit and storage.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Transportation</p>
            <p className="content">
              We can transport your belongings to our storage facilities using
              our fleet of modern, well-maintained vehicles. Our drivers are
              experienced and skilled at handling all types of items, from
              fragile glassware to heavy furniture, with care and precision.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Customized Solutions</p>
            <p className="content">
              We understand that every storage need is unique, which is why we
              offer customized storage solutions tailored to your specific
              requirements. Whether you need short-term storage for a few weeks
              or long-term warehousing for several months, we'll work with you
              to develop a plan that meets your needs and budget.
            </p>
          </div>
        </Col>
        <Col lg="12">
          <p className="content text-black mt-4">
            At KHALSA PACKERS AND MOVERS PVT.LTD, we're committed to providing
            top-quality warehousing and storage services that you can trust.
            Whether you're moving, renovating, or just need some extra space,
            you can rely on us to keep your belongings safe and secure until
            you're ready for them.
          </p>
          <Link to="/#enquiryForm" className="text-black text-decoration-none">
            <div className="button-conclusion d-flex justify-content-center mt-5 align-items-center">
              <img loading="lazy"src={call} alt="Call" className="me-4" />
              <p className="text-black text-decoration-none">
                Contact us today to learn more about our warehousing and storage
                solutions and how we can help you with your storage needs.
              </p>
            </div>
          </Link>
        </Col>
      </Row>
    </section>
  );
};

export default WarehouseAndStorage;
