import React from "react";
import "./index.scss";
import CustomBreadcrumb from "../../components/common/Breadcrumb/Custom_Breadcrumb";
import { Col, Row } from "reactstrap";
import { call, loading } from "../../assets/images";
import { Link } from "react-router-dom";

const LoadingAndUnloading = () => {
  return (
    <section className="services">
      <CustomBreadcrumb
        name="Our Services"
        secondName="Loading And Unloading"
      />
      <Row className="m-0 mt-5 p-3 p-lg-5">
        <Col lg="8">
          <img loading="lazy"src={loading} alt="Loading_Unloading" className="w-100" />
          <p className="service_name mt-5">Loading And Unloading</p>
          <p className="service_description mt-3">
            Welcome to our loading and unloading services, where we specialize
            in ensuring the safe and efficient handling of your belongings
            during the moving process. With our experienced team and dedication
            to excellence, we're here to make your move a breeze from start to
            finish.
          </p>
        </Col>
        <Col lg="4">
          <div className="our-services p-3 p-lg-5">
            <p className="header">Our Services</p>
            <ul>
              <li>International Relocation</li>
              <li>Transportation Services</li>
              <li>Warehousing and Storage</li>
              <li>Loading and Unloading</li>
              <li>Office Relocation</li>
              <li>Car Transportation</li>
              <li>Bike Transportation</li>
              <li>Intercity Moves</li>
              <li>Transit Insurance</li>
              <li>Heavy Machinery Shifting</li>
            </ul>
          </div>
        </Col>
        <Col lg="12">
          <p className="sub-head">
            Why Choose Us for Your Loading and Unloading Needs?
          </p>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Expertise</p>
            <p className="content">
              Our team of movers is highly trained and experienced in loading
              and unloading all types of items, from furniture and appliances to
              delicate electronics and antiques. We have the skills and
              knowledge to ensure that your belongings are handled with care and
              precision throughout the moving process.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Efficiency</p>
            <p className="content">
              We understand that time is of the essence when it comes to moving,
              which is why we prioritize efficiency in everything we do. Our
              movers work quickly and methodically to load and unload your
              belongings, minimizing downtime and ensuring a smooth transition
              to your new location.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Safety</p>
            <p className="content">
              The safety of your belongings is our top priority. We use
              professional-grade equipment and techniques to secure and protect
              your items during loading and unloading, reducing the risk of
              damage or loss. You can trust us to treat your belongings with the
              same care and respect as if they were our own.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Flexibility</p>
            <p className="content">
              Whether you're moving locally or long-distance, moving into a
              house or an apartment, we offer flexible loading and unloading
              services to meet your needs. We can accommodate moves of all sizes
              and complexities, ensuring that your belongings arrive safely and
              on time, wherever you're going.
            </p>
          </div>
        </Col>
        <Col lg="12">
          <p className="sub-head">Our Loading and Unloading Process </p>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Preparation</p>
            <p className="content">
              Before we begin loading your belongings, our team will conduct a
              thorough assessment of your items and develop a plan for how to
              best pack and organize them for transport. We'll also take the
              time to ensure that any fragile or valuable items are properly
              protected and secured.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Loading</p>
            <p className="content">
              Using professional-grade moving equipment and techniques, our
              movers will carefully load your belongings onto our trucks or
              vehicles. We'll make sure that everything is properly packed and
              secured to prevent shifting or damage during transit.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Transportation</p>
            <p className="content">
              Once everything is loaded securely, we'll transport your
              belongings to your new location using our fleet of modern,
              well-maintained vehicles. Our drivers are experienced and skilled
              at navigating safely to your destination, no matter how near or
              far.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Unloading</p>
            <p className="content">
              Upon arrival at your new location, our team will unload your
              belongings with the same care and attention to detail as we did
              during the loading process. We'll place each item in its
              designated location according to your instructions, making sure
              that everything is accounted for and undamaged.
            </p>
          </div>
        </Col>
        <Col lg="12">
          <p className="content text-black mt-4">
            At KHALSA PACKERS AND MOVERS PVT LTD], we're committed to providing
            top-quality loading and unloading services that you can trust.
            Whether you're moving across town or across the country, you can
            rely on us to handle your belongings with care and professionalism
            every step of the way.
          </p>
          <Link to="/#enquiryForm" className="text-black text-decoration-none">
            <div className="button-conclusion d-flex justify-content-center mt-5 align-items-center">
              <img loading="lazy"src={call} alt="Call" className="me-4" />
              <p className="text-black text-decoration-none">
                Contact us today to learn more about our loading and unloading
                services and how we can make your next move a stress-free
                experience.
              </p>
            </div>
          </Link>
        </Col>
      </Row>
    </section>
  );
};

export default LoadingAndUnloading;
