import React from "react";
import "./index.scss";
import CustomBreadcrumb from "../../components/common/Breadcrumb/Custom_Breadcrumb";
import { Col, Row } from "reactstrap";
import { bikeTransportation, call } from "../../assets/images";
import { Link } from "react-router-dom";

const BikeTransportation = () => {
  return (
    <section className="services">
      <CustomBreadcrumb name="Our Services" secondName="Bike Transportation" />
      <Row className="m-0 mt-5 p-3 p-lg-5">
        <Col lg="8">
          <img
            src={bikeTransportation}
            alt="Bike_Transport"
            className="w-100"
          />
          <p className="service_name mt-5">Bike Transportation</p>
          <p className="service_description mt-3">
            Welcome to our specialized bike transportation services, where we
            ensure the safe and secure transport of your beloved bicycles to
            their destination. Whether you're a casual rider, a passionate
            cyclist, or a professional athlete, we understand the value of your
            bikes and take great care in handling them throughout the
            transportation process.
          </p>
        </Col>
        <Col lg="4">
          <div className="our-services p-3 p-lg-5">
            <p className="header">Our Services</p>

            <ul>
              <Link to="/services/international-relocation">
                <li>International Relocation</li>
              </Link>
              <li>
                <Link to="/services/transportation-services">
                  Transportation Services
                </Link>
              </li>
              <li>
                <Link to="/services/warehousing-and-storage">
                  Warehousing and Storage
                </Link>
              </li>
              <li>
                <Link to="/services/loading-and-unloading">
                  Loading and Unloading
                </Link>
              </li>
              <li>
                <Link to="/services/office-relocation">Office Relocation</Link>
              </li>
              <li>
                <Link to="/services/car-transportation">
                  Car Transportation
                </Link>
              </li>
              <li>
                <Link to="/services/bike-transportation">
                  Bike Transportation
                </Link>
              </li>
              <li>
                <Link to="/services/intercity-moves">Intercity Moves</Link>
              </li>
              <li>
                <Link to="/services/transit-insurance">Transit Insurance</Link>
              </li>
              <li>
                <Link to="/services/heavy-machinery-shifting">
                  Heavy Machinery Shifting
                </Link>
              </li>
            </ul>
          </div>
        </Col>
        <Col lg="12">
          <p className="sub-head">Why Choose Us for Your Bike Transportation</p>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Expertise in Bike Handling</p>
            <p className="content">
              Our team is trained and experienced in handling all types of
              bicycles, from road bikes and mountain bikes to electric bikes and
              specialty models. We understand the unique requirements of bike
              transportation and take the utmost care to ensure that your bikes
              arrive safely and undamaged.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Customized Transportation Solutions</p>
            <p className="content">
              Whether you're moving locally or across the country, we offer
              customized transportation solutions tailored to your specific
              needs. From single bikes to entire collections, we have the
              resources and capabilities to handle bike transportation of any
              scale.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Safe and Secure Transport</p>
            <p className="content">
              We prioritize the safety and security of your bikes throughout the
              transportation process. Our vehicles are equipped with specialized
              racks, straps, and padding to securely hold your bikes in place
              during transit, minimizing the risk of damage or loss.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Efficient and Timely Delivery</p>
            <p className="content">
              We understand that timing is important when it comes to bike
              transportation, especially if you're preparing for a race or
              event. Our team works efficiently to ensure that your bikes are
              transported to their destination on time, allowing you to focus on
              your cycling adventures.
            </p>
          </div>
        </Col>
        <Col lg="12">
          <p className="sub-head">Our Bike Transportation Process</p>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Preparation</p>
            <p className="content">
              Our team will work with you to assess your bike transportation
              needs and develop a plan for the move. We'll discuss any special
              requirements or instructions for handling your bikes and ensure
              that they are properly prepared for transport.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Packaging and Loading</p>
            <p className="content">
              We'll carefully package your bikes using high-quality materials
              and techniques to protect them during transit. Our team will then
              load your bikes onto our specialized transportation vehicles,
              ensuring that they are securely strapped and padded for added
              protection.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Transportation</p>
            <p className="content">
              Our drivers are experienced in navigating local and long-distance
              routes, ensuring that your bikes are transported safely and
              efficiently to their destination. We'll provide regular updates on
              the status of your shipment and coordinate delivery according to
              your schedule.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Unloading and Delivery</p>
            <p className="content">
              Upon arrival at the destination, our team will carefully unload
              your bikes and ensure that they are delivered to your desired
              location. We'll assist with unpacking and assembly as needed,
              ensuring that your bikes are ready for your next cycling
              adventure.
            </p>
          </div>
        </Col>
        <Col lg="12">
          <p className="content text-black mt-4">
            At KHALSA PACKERS AND MOVERS PVT LTD , we're committed to providing
            top-quality bike transportation services that you can trust. Whether
            you're moving, traveling, or shipping your bikes for an event, you
            can rely on us to handle your bikes with professionalism, care, and
            attention to detail.
          </p>
          <Link to="/#enquiryForm" className="text-black text-decoration-none">
            <div className="button-conclusion d-flex justify-content-center mt-5 align-items-center">
              <img loading="lazy"src={call} alt="Call" className="me-4" />
              <p className="text-black text-decoration-none">
                Contact us today to learn more about our bike transportation
                services and how we can help you with your next bike move.
              </p>
            </div>
          </Link>
        </Col>
      </Row>
    </section>
  );
};

export default BikeTransportation;
