import React from "react";
import { whatsapp_white } from "../../../assets/images";
import "./WhatsappButton.scss";
import { Link } from "react-router-dom";

const WhatsappButton = () => {
  return (
    <section className="whatsapp_button ">
      <Link to="https://wa.me/918541893616" target="_blank">
        <img loading="lazy"src={whatsapp_white} alt="Whatsapp_Icon" />
      </Link>
    </section>
  );
};

export default WhatsappButton;
