import React from "react";
import "./Faq.scss";
import CustomBreadcrumb from "../../components/common/Breadcrumb/Custom_Breadcrumb";

const Faq = () => {
  const faqData = [
    {
      question: "Why should I choose Khalsa Packers and Movers Pvt Ltd?",
      answer:
        "Khalsa Packers & Movers is dedicated to providing seamless and stress-free relocation services. Our experienced team, commitment to quality, and customer-centric approach set us apart in the industry.",
    },
    {
      question: "What services do you offer?",
      answer:
        "We offer a comprehensive range of packing and moving services, including household relocation, office shifting, vehicle transportation, and storage solutions. Our goal is to cater to all your moving needs.",
    },
    {
      question: "How do I get a quote for my move?",
      answer:
        "Getting a quote is easy! Simply fill out our online quote form on the website, providing details about your move. Our team will promptly assess your requirements and provide you with a customized quote.",
    },

    {
      question: "Are my belongings insured during the move?",
      answer:
        "Yes, we offer transit insurance to safeguard your belongings during transportation. Our team will guide you through the insurance options available, ensuring peace of mind throughout the relocation process.",
    },
    {
      question: "Can I track my shipment in real-time?",
      answer:
        "Absolutely! We provide real-time tracking services so you can monitor the status of your shipment. Stay informed about the progress of your move at every step.",
    },
    {
      question: "How do you ensure the safety of my items during the move?",
      answer:
        "Our skilled team uses high-quality packing materials and follows industry best practices to ensure the safety of your belongings. We take every precaution to minimize the risk of damage during transportation.",
    },
    {
      question: "What areas do you serve?",
      answer:
        "Khalsa Packers & Movers operates across [list of cities/regions]. Whether you’re moving locally or long-distance, we have the expertise to handle your relocation efficiently.",
    },
    {
      question: "Can you help with international moves?",
      answer:
        "Yes, we provide international moving services. Our network of partners ensures a smooth transition, offering comprehensive support for your international relocation needs.",
    },
    {
      question: "What if I need to reschedule my move?",
      answer:
        "We understand that plans can change. Please contact our customer support team as soon as possible, and we will work with you to reschedule your move according to your new timeline.",
    },
    {
      question: "How can I contact Khalsa for further assistance?",
      answer:
        "You can reach us through our Contact Us page on the website, where you’ll find our phone numbers and email addresses. Our customer support team is ready to assist you with any queries or concerns.",
    },
  ];
  return (
    <section className="faq ">
      <CustomBreadcrumb name="FAQ's" />
      <div className="faq-accordion">
        <p className="heading">Frequently Asked Questions</p>
        <div className="outer-box">
          <div class="accordion" id="accordionExample">
            {faqData.map((item, index) => (
              <div class="accordion-item mt-3  border-2 rounded" key={index}>
                <h2 class="accordion-header" id={`heading${index + 1}`}>
                  <button
                    class="accordion-button rounded collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${index + 1}`}
                    aria-expanded="true"
                    aria-controls={`collapse${index + 1}`}
                  >
                    {item.question}
                  </button>
                </h2>
                <div
                  id={`collapse${index + 1}`}
                  class="accordion-collapse collapse"
                  aria-labelledby={`heading${index + 1}`}
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">{item.answer}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
