import React from "react";
import "./OurServices.scss";
import { Col, Row } from "reactstrap";
import {
  service11,
  service12,
  service13,
  service14,
  service15,
  service3,
  service4,
  service6,
  service7,
  service9,
} from "../../../assets/images";
import { Link } from "react-router-dom";

const OurServices = () => {
  const service = [
    {
      image: service3,
      title: "International Relocation",
      content:
        "As a leading firm in the field of packers and movers, we are capable to offer you the most advanced international transportation facilities.",
      link: "/services/international-relocation",
    },

    {
      image: service6,
      title: "Transportation Services",
      content:
        "Khalsa Packers And Movers is well known Transportation Service company in India, which are providing all type Transportation Services.",
      link: "/services/transportation-services",
    },
    {
      image: service7,
      title: "Car Transportation",
      content:
        "Khalsa Packers And Movers is well known Car Transportation Service company in India, which are providing Car Transportation Services.",
      link: "/services/car-transportation",
    },

    {
      image: service9,
      title: "Loading And Unloading",
      content:
        "We avail loading and unloading of small things like househols small furnitures to the great scale materials required for rebuilding the company or industry.",
      link: "/services/loading-and-unloading",
    },
    {
      image: service12,
      title: "Office Relocation",
      content:
        "Wether it's a simple moving out from one to another or you give complete change to your office. Our staff is experienced.",
      link: "/services/office-relocation",
    },
    {
      image: service13,
      title: "Heavy Machinery Shifting",
      content:
        "Khalsa Packers And Movers provide Industrial and Machinery Moving service in India. With the use of Latest & advanced technology.",
      link: "/services/heavy-machinery-shifting",
    },
    {
      image: service14,
      title: "Intercity Moves",
      content:
        "Seamlessly transition between cities with our expert moving services—making every intercity move feel like coming home.",
      link: "/services/intercity-moves",
    },
    {
      image: service4,
      title: "Bike Transportation",
      content:
        "Bikes Transportation, includes the transportation of your bikes ranging from all models. We have mechanism working for all.",
      link: "/services/bike-transportation",
    },
    {
      image: service11,
      title: "Warehousing And Storage",
      content:
        "To all the goods that you have to store for other business purposes , we give you the offer to customize into one particular warehouse stores.",
      link: "/services/warehousing-and-storage",
    },
    {
      image: service15,
      title: "Transit Insurance",
      content:
        "We give best Insurance offers. We are among them, who believes in the honesty of work we serve. If we ever create any damages to any of the goods.",
      link: "/services/transit-insurance",
    },
  ];
  return (
    <section className="services-home p-2 p-lg-5">
      <p className="heading">Our Services</p>
      <p className="description">
        <b>“Khalsa Packers And Movers Pvt. Ltd.”</b> introduces you our products
        and services and all are amazing services we provide to you from safely
        packing your belongings to transporting them to new destination, packers
        and movers make relocation breeze. We handle shipment of various goods
        from furniture to fragile items with care, ensuring a smooth moving out
        and moving to any destination.
      </p>
      <Row className="m-0 mt-5 justify-content-center all_services">
        {service.map((item) => {
          return (
            <Col lg="4" xl="4" md="6" className="my-3" key={item.title}>
              <Link to={item.link} className="text-decoration-none">
                <div className="service_box d-flex flex-column align-items-center  ">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="img-fluid"
                  />
                  <p className="service_title">{item.title}</p>
                  <p className="service_content">{item.content}</p>
                </div>
              </Link>
            </Col>
          );
        })}
      </Row>
    </section>
  );
};

export default OurServices;
