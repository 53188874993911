import React, { useEffect } from "react";
import HeroSection from "../../components/UI/HeroSection/HeroSection";
import AboutUs from "../../components/UI/AboutUs/AbousUs";
import Benefits from "../../components/UI/Benefits/Benefits";
import OurServices from "../../components/UI/OurServices/OurServices";
import PartnersDirector from "../../components/UI/PartnersDirector/PartnersDirector";
import ClientSlider from "../../components/UI/ClientSilder/ClientSlider";
import Testimonials from "../../components/UI/Testimonials/Testimonials";
import EnquiryForm from "../../components/UI/EnquiryForm/EnquiryForm";
import { useLocation } from "react-router-dom";

const LandingPage = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash === "#enquiryForm") {
      const enquiryFormElement = document.getElementById("enquiryForm");
      if (enquiryFormElement) {
        enquiryFormElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  return (
    <>
      <HeroSection />
      <AboutUs />
      <EnquiryForm />
      <Benefits />
      <PartnersDirector />
      <OurServices />
      <Testimonials />
      <ClientSlider />
    </>
  );
};

export default LandingPage;
