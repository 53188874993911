import React from "react";
import CustomBreadcrumb from "../../components/common/Breadcrumb/Custom_Breadcrumb";
import "./AboutUs.scss";
import { Col, Container, Row } from "reactstrap";
import { green_tick } from "../../assets/images";

const AboutUs = () => {
  return (
    <section className="about_us">
      <CustomBreadcrumb name="About Us" />
      <Container className="p-4 p-lg-5">
        <div className="border-box mt-3 mt-5 ">
          <p className="sub-head mb-3 mb-lg-5">
            Welcome to Khalsa Packers And Movers Pvt.Ltd.
          </p>
          <p className="desc">
            Your reliable partner in relocation services. We understand that
            moving can be a daunting task, filled with stress and uncertainties.
            That's why we're here—to simplify your move and make it as smooth as
            possible.
            <br />
            <br /> We have all the types of transportations that will make a
            easy for your materials to move up into certain place . We have a
            delicate members where we can ensure you the best results and the
            safe landing to your products. As safety of your belongings is our
            top-notch priority.
          </p>
        </div>
        <div className="border-box mt-3 mt-5 ">
          <p className="sub-head mb-3 mb-lg-5">Who We Are</p>
          <p className="desc">
            Khalsa Packers And Movers Pvt.Ltd. is a leading provider of packing
            and moving solutions, catering to both residential and commercial
            clients. With years of experience in the industry, we have earned a
            reputation for excellence, integrity, and professionalism.
          </p>
        </div>
        <div className="border-box mt-3 mt-5 ">
          <p className="sub-head mb-3 mb-lg-5">Our Mission</p>
          <p className="desc">
            Our mission is simple: to provide top-notch moving services that
            exceed our customers' expectations. We strive to deliver stress-free
            relocation experiences, ensuring that your belongings reach their
            destination safely and on time.
          </p>
        </div>
        <p className="sub-head mt-3 mt-5 ">What Sets Us Apart</p>
        <p className="desc">
          At Khalsa Packers And Movers Pvt. Ltd., we differentiate ourselves
          through:
        </p>
        <Row className="m-0 mt-3 mt-5 ">
          <Col lg="4" className="mt-3 mt-lg-0">
            <div className="outer-box-about">
              <p className="title">Expertise</p>
              <p className="content mt-3">
                Our team comprises highly skilled professionals who are experts
                in packing, loading, transporting, and unloading. We handle your
                belongings with care and precision, treating them as if they
                were our own.
              </p>
            </div>
          </Col>
          <Col lg="4" className="mt-3 mt-lg-0">
            <div className="outer-box-about">
              <p className="title">Customized Solutions</p>
              <p className="content mt-3">
                We understand that every move is unique. That's why we offer
                personalized solutions tailored to your specific needs and
                preferences. Whether you're moving locally or across the
                country, we have the resources and expertise to make it happen
                seamlessly.
              </p>
            </div>
          </Col>
          <Col lg="4" className="mt-3 mt-lg-0">
            <div className="outer-box-about">
              <p className="title">Reliability</p>
              <p className="content mt-3">
                Our team comprises highly skilled professionals who are experts
                in packing, loading, transporting, and unloading. We handle your
                belongings with care and precision, treating them as if they
                were our own.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="m-0 mt-3 mt-5 ">
          <p className="sub-head">Best Packing Practices</p>
          <p className="desc mt-3 mt-lg-5 ">
            When you choose Khalsa Packers And Movers Pvt.Ltd., you can count on
            us to deliver on our promises. We take pride in our reliability and
            accountability, ensuring that your move is executed with the utmost
            care and attention to detail.
          </p>
          <Col lg="12" className="mt-5">
            <div className="horizontal_box">
              <div className="header d-flex justify-content-center">
                <img
                  src={green_tick}
                  alt="Green_Tick"
                  className="object-fit-contain"
                />
                <p className="ms-3">Quality Materials</p>
              </div>
              <p className="content mt-4">
                We use only the highest quality packing materials, including
                sturdy boxes, bubble wrap, packing paper, and packing tape.
                These materials provide excellent protection against bumps,
                vibrations, and other hazards during transit.
              </p>
            </div>
          </Col>
          <Col lg="12" className="mt-5">
            <div className="horizontal_box">
              <div className="header d-flex justify-content-center">
                <img
                  src={green_tick}
                  alt="Green_Tick"
                  className="object-fit-contain"
                />
                <p className="ms-3">Proper Packing Techniques</p>
              </div>
              <p className="content mt-4">
                Our packers are trained in proper packing techniques to maximize
                efficiency and safety. They know how to wrap and cushion fragile
                items, disassemble large furniture pieces, and pack items of all
                shapes and sizes securely.
              </p>
            </div>
          </Col>
          <Col lg="12" className="mt-5">
            <div className="horizontal_box">
              <div className="header d-flex justify-content-center">
                <img
                  src={green_tick}
                  alt="Green_Tick"
                  className="object-fit-contain"
                />
                <p className="ms-3">Customized Packing Solutions</p>
              </div>
              <p className="content mt-4">
                We understand that every item is unique and requires special
                attention. That's why we offer customized packing solutions
                tailored to your specific needs. Whether you have delicate,
                bulky furniture, or valuable artwork, we have the expertise to
                pack it safely and securely.
              </p>
            </div>
          </Col>
          <Col lg="12" className="mt-5">
            <div className="horizontal_box">
              <div className="header d-flex justify-content-center">
                <img
                  src={green_tick}
                  alt="Green_Tick"
                  className="object-fit-contain"
                />
                <p className="ms-3">Labelling and Organization</p>
              </div>
              <p className="content mt-4">
                Organization is key to a successful move. We carefully label
                each box with its contents and destination room to ensure easy
                unpacking at your new home or office. This helps streamline the
                moving process and minimizes confusion during unpacking.
              </p>
            </div>
          </Col>
          <Col lg="12" className="mt-5">
            <div className="horizontal_box">
              <div className="header d-flex justify-content-center">
                <img
                  src={green_tick}
                  alt="Green_Tick"
                  className="object-fit-contain"
                />
                <p className="ms-3">Securing Items for Transit</p>
              </div>
              <p className="content mt-4">
                Once everything is packed and labeled, we take additional steps
                to secure your items for transit. We use straps and tie-downs to
                prevent shifting and movement inside the moving truck, further
                reducing the risk of damage during transportation.
              </p>
            </div>
          </Col>
          <Col lg="12" className="mt-5">
            <div className="horizontal_box">
              <div className="header d-flex justify-content-center">
                <img
                  src={green_tick}
                  alt="Green_Tick"
                  className="object-fit-contain"
                />
                <p className="ms-3">Attention to Detail</p>
              </div>
              <p className="content mt-4">
                From fragile glassware to bulky furniture, we handle each item
                with the utmost care and attention to detail. Our goal is to
                ensure that your belongings arrive at their destination in the
                same condition as when they left, allowing you to move with
                peace of mind.
              </p>
            </div>
          </Col>
          <Col lg="12" className="mt-5">
            <div className="horizontal_box">
              <div className="header d-flex justify-content-center">
                <img
                  src={green_tick}
                  alt="Green_Tick"
                  className="object-fit-contain"
                />
                <p className="ms-3">Quality Materials</p>
              </div>
              <p className="content mt-4">
                We use only the highest quality packing materials, including
                sturdy boxes, bubble wrap, packing paper, and packing tape.
                These materials provide excellent protection against bumps,
                vibrations, and other hazards during transit.
              </p>
            </div>
          </Col>
          <p className="desc mt-3 mt-5  mb-3 mb-lg-5">
            By adhering to these best packing practices, we strive to provide
            our customers with a stress-free and successful moving experience.
            Trust Khalsa Packers And Movers Pvt.Ltd. for all your packing and
            moving needs, and let us take the hassle out of your next move.
          </p>
        </Row>
      </Container>
    </section>
  );
};

export default AboutUs;
