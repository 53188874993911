import React from "react";
import CustomBreadcrumb from "../../components/common/Breadcrumb/Custom_Breadcrumb";
import { Col, Row } from "reactstrap";
import "./Clients.scss";
// import "../../assets/images/clients/client1.jpg";
// import "";
const Clients = () => {
  return (
    <section className="clients">
      <CustomBreadcrumb name="Clients" />
      <Row className="m-0 mt-5 p-3 p-lg-3 ">
        {[...Array(55)].map((_, index) => (
          <Col
            key={`client${index + 1}`}
            // xl="2"
            // lg="2"
            className="my-2 text-center d-flex flex-wrap custom-col"
          >
            <img
              src={`/clients/client${index + 1}.jpg`}
              alt={`client${index + 1}`}
              className="client_logo p-3"
            />
          </Col>
        ))}
      </Row>
    </section>
  );
};

export default Clients;
