import React from "react";
import CustomBreadcrumb from "../../components/common/Breadcrumb/Custom_Breadcrumb";
import { Container } from "reactstrap";
import "./TermsAndConditions.scss";

const TermsAndConditions = () => {
  return (
    <section className="terms-and-conditions">
      <CustomBreadcrumb name="Terms And Conditions" />
      <div className="custom">
        <Container>
          <p className="heading">
            Khalsa Packers and Movers Pvt. Ltd. have certain terms and
            conditions to be followed by you while availing our services. These
            include:
          </p>
          <ul className="mt-5">
            <li>
              Khalsa Packers and Movers Pvt. Ltd. or its representative will not
              be responsible for any kind of damages or loss occurred to your
              goods due to natural calamity, unforeseen accident, fire, riots or
              sabotage. Therefore, to avoid such loss, we advise you to insure
              your shipment to avoid risk.
            </li>
            <li>
              We are not responsible to undertake electrical, carpentry, or
              plumbing work. If needed we will provide those depending upon the
              availability of the staff with extra charges.
            </li>
            <li>
              We undertake the risk of your goods, but no individual
              policy/receipt from the insurance company will be given.
            </li>
            <li>
              We provide storage facility to our customers at a lower rate.
              However, for loading, unloading, and local delivery, we take extra
              charges.
            </li>
            <li>
              We would be thankful if you intimate us 3-4 days earlier to
              deliver hassle-free packaging.
            </li>
            <li>Please pay 25% in advance on the total amount.</li>
            <li>
              If you want to take extra goods apart from those mentioned in the
              goods list you are welcome. However, we charge extra money for
              such items.
            </li>
            <li>
              For car transportation, it is mandatory that your car should
              contain an adequate amount of Petrol or Diesel.
            </li>
            <li>
              After local shifting, corrugated boxes should be returned on the
              same day. If the customer is sending the boxes the next day, we
              charge a certain amount.
            </li>
            <li>
              We will charge extra in case you call our packers on the
              subsequent day for your convenience.
            </li>
          </ul>
          <h6>
            It is to be noted that the above mentioned terms and conditions may
            change in future as per the requirement of Khalsa Packers and Movers
            Pvt. Ltd.
          </h6>
          <p className="heading">
            Our Policy for the Cancellation and Refund are as follows:
          </p>
          <p className="mt-3 mb-3 sub-head">Cancellation Policy</p>
          <p>
            In case you wish to cancel your orders placed at Khalsa Packers and
            Movers Pvt. Ltd., you could do so within 24 hrs of the orders
            placed. The cancellation would not be done once the consignment has
            already been shipped.
          </p>
          <p className="mt-3 mb-3 sub-head">Refund Policy</p>
          <p>
            In case of a cancellation, please get in touch with our customer
            care for a refund of your money with all required proofs.
          </p>
          <p className="mt-3 mb-3 sub-head">Pricing Policy</p>
          <p>
            The rates are subject to the nature and kind of services you avail
            as per your requirement which may depend upon its factors such as
            volume, distance and for other ancillary services desired to avail.
          </p>
          <br />
          <p>
            Furthermore, rates of the services are likely to change without
            prior notice.
          </p>
        </Container>
      </div>
    </section>
  );
};

export default TermsAndConditions;
