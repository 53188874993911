import React from "react";
import "./Custom_Breadcrumb.scss";

const CustomBreadcrumb = ({ name, secondName }) => {
  return (
    <div className="custom_breadcrumb position-relative">
      {/* <img loading="lazy"src={faq_bg} alt="Faq_bg" className="bg-image" /> */}
      <div className="content">
        {secondName ? secondName.toUpperCase() : name.toUpperCase()}
        <div className="d-flex align-items-center gap-1 mt-2 address justify-content-center  flex-md-row">
          <p className="">Home</p>
          <svg
            className=""
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
          >
            <path
              d="M24.5417 15.5L6.45842 15.5"
              stroke="#FFC800"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.5001 24.5417L24.5417 15.5L15.5001 6.45833"
              stroke="#FFC800"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p className="">{name}</p>
          {secondName && (
            <>
              <svg
                className=""
                xmlns="http://www.w3.org/2000/svg"
                width="31"
                height="31"
                viewBox="0 0 31 31"
                fill="none"
              >
                <path
                  d="M24.5417 15.5L6.45842 15.5"
                  stroke="#FFC800"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15.5001 24.5417L24.5417 15.5L15.5001 6.45833"
                  stroke="#FFC800"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p>{secondName}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomBreadcrumb;
