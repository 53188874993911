import React from "react";
import "./index.scss";
import CustomBreadcrumb from "../../components/common/Breadcrumb/Custom_Breadcrumb";
import { Col, Row } from "reactstrap";
import { call, office } from "../../assets/images";
import { Link } from "react-router-dom";

const OfficeRelocation = () => {
  return (
    <section className="services">
      <CustomBreadcrumb name="Our Services" secondName="Office Relocation" />
      <Row className="m-0 mt-5 p-3 p-lg-5">
        <Col lg="8">
          <img loading="lazy"src={office} alt="Office_Relocation" className="w-100" />
          <p className="service_name mt-5">Office Relocation</p>
          <p className="service_description mt-3">
            Welcome to our dedicated office relocation services, where we
            specialize in ensuring a smooth and stress-free transition for
            businesses moving to new locations. With our expertise and tailored
            solutions, we're here to make your office move a success from start
            to finish.
          </p>
        </Col>
        <Col lg="4">
          <div className="our-services p-3 p-lg-5">
            <p className="header">Our Services</p>
            <ul>
              <li>
                <Link to="/services/international-relocation">
                  International Relocation
                </Link>
              </li>
              <li>
                <Link to="/services/transportation-services">
                  Transportation Services
                </Link>
              </li>
              <li>
                <Link to="/services/warehousing-and-storage">
                  Warehousing and Storage
                </Link>
              </li>
              <li>
                <Link to="/services/loading-and-unloading">
                  Loading and Unloading
                </Link>
              </li>
              <li>
                <Link to="/services/office-relocation">Office Relocation</Link>
              </li>
              <li>
                <Link to="/services/car-transportation">
                  Car Transportation
                </Link>
              </li>
              <li>
                <Link to="/services/bike-transportation">
                  Bike Transportation
                </Link>
              </li>
              <li>
                <Link to="/services/intercity-moves">Intercity Moves</Link>
              </li>
              <li>
                <Link to="/services/transit-insurance">Transit Insurance</Link>
              </li>
              <li>
                <Link to="/services/heavy-machinery-shifting">
                  Heavy Machinery Shifting
                </Link>
              </li>
            </ul>
          </div>
        </Col>
        <Col lg="12">
          <p className="sub-head">Why Choose Us for Your Office Relocation? </p>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Experience</p>
            <p className="content">
              With years of experience in the moving industry, we have perfected
              the art of office relocation. Our team is well-versed in the
              unique challenges and requirements of moving businesses, ensuring
              that every aspect of your relocation is handled with care and
              efficiency.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Customized Solutions</p>
            <p className="content">
              We understand that every office relocation is unique, which is why
              we offer customized solutions tailored to your specific needs and
              preferences. Whether you're moving a small startup or a large
              corporate office, we have the resources and expertise to deliver
              exceptional results.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Comprehensive Services</p>
            <p className="content">
              From planning and packing to transportation and unpacking, we
              offer a comprehensive range of services to cover every aspect of
              your office relocation. Our goal is to minimize downtime and
              disruptions to your business operations, allowing you to focus on
              what you do best.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Professional Team</p>
            <p className="content">
              Our team of movers is professional, experienced, and dedicated to
              providing the highest level of service. We handle your office
              equipment, furniture, and sensitive documents with the utmost care
              and attention to detail, ensuring that everything arrives safely
              at your new location.
            </p>
          </div>
        </Col>
        <Col lg="12">
          <p className="sub-head">Our Office Relocation Process</p>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Planning and Preparation</p>
            <p className="content">
              Our team works closely with you to develop a detailed relocation
              plan, outlining key milestones and deadlines for each phase of the
              move. We take care of all the logistics, including packing
              supplies, furniture disassembly, and transportation arrangements.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title"> Packing and Labeling</p>
            <p className="content">
              Our expert movers carefully pack and label all of your office
              belongings, ensuring that everything is organized and protected
              during transit. We use high-quality packing materials and
              techniques to minimize the risk of damage and ensure a smooth
              transition.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Transportation and Delivery</p>
            <p className="content">
              We coordinate the transportation of your office items to your new
              location, using state-of-the-art vehicles and equipment to ensure
              safe and efficient delivery. Our team works quickly and
              efficiently to minimize downtime and disruptions to your business
              operations.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Unpacking and Setup</p>
            <p className="content">
              Once we arrive at your new location, our movers will unpack and
              set up your office furniture and equipment according to your
              specifications. We'll also assist with any additional services you
              may need, such as furniture assembly or installation of technology
              equipment.
            </p>
          </div>
        </Col>
        <Col lg="12">
          <p className="content text-black mt-4">
            At KHALSA PACKERS AND MOVERS PVT.LTD., we understand the importance
            of a successful office relocation and are committed to providing the
            highest level of service to our clients. With our expertise,
            professionalism, and dedication to customer satisfaction, we'll make
            your office move a seamless and hassle-free experience.
          </p>
          <Link to="/#enquiryForm" className="text-black text-decoration-none">
            <div className="button-conclusion d-flex justify-content-center mt-5 align-items-center">
              <img loading="lazy"src={call} alt="Call" className="me-4" />
              <p className="text-black text-decoration-none">
                Contact us today to learn more about our office relocation
                services and how we can help you make a smooth transition to
                your new workspace.
              </p>
            </div>
          </Link>
        </Col>
      </Row>
    </section>
  );
};

export default OfficeRelocation;
