import React from "react";
import "./index.scss";
import CustomBreadcrumb from "../../components/common/Breadcrumb/Custom_Breadcrumb";
import { Col, Row } from "reactstrap";
import { call, international } from "../../assets/images";
import { Link } from "react-router-dom";

const InternationalRelocation = () => {
  return (
    <section className="services">
      <CustomBreadcrumb
        name="Our Services"
        secondName="International Relocation"
      />
      <Row className="m-0 mt-5 p-3 p-lg-5">
        <Col lg="8">
          <img
            src={international}
            alt="International_Relocation"
            className="w-100"
          />
          <p className="service_name mt-5">International Relocation</p>
          <p className="service_description mt-3">
            Welcome to our international relocation services, where we
            specialize in providing comprehensive and stress-free solutions for
            individuals and families moving abroad. With our expertise in
            international logistics and global relocation, we're here to ensure
            that your move to a new country is smooth, efficient, and
            hassle-free.
          </p>
        </Col>
        <Col lg="4">
          <div className="our-services p-3 p-lg-5">
            <p className="header">Our Services</p>
            <ul>
              <li>
                <Link to="/services/international-relocation">
                  International Relocation
                </Link>
              </li>
              <li>
                <Link to="/services/transportation-services">
                  Transportation Services
                </Link>
              </li>
              <li>
                <Link to="/services/warehousing-and-storage">
                  Warehousing and Storage
                </Link>
              </li>
              <li>
                <Link to="/services/loading-and-unloading">
                  Loading and Unloading
                </Link>
              </li>
              <li>
                <Link to="/services/office-relocation">Office Relocation</Link>
              </li>
              <li>
                <Link to="/services/car-transportation">
                  Car Transportation
                </Link>
              </li>
              <li>
                <Link to="/services/bike-transportation">
                  Bike Transportation
                </Link>
              </li>
              <li>
                <Link to="/services/intercity-moves">Intercity Moves</Link>
              </li>
              <li>
                <Link to="/services/transit-insurance">Transit Insurance</Link>
              </li>
              <li>
                <Link to="/services/heavy-machinery-shifting">
                  Heavy Machinery Shifting
                </Link>
              </li>
            </ul>
          </div>
        </Col>
        <Col lg="12">
          <p className="sub-head">
            Why Choose Us for Your International Relocation?
          </p>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Global Expertise</p>
            <p className="content">
              Our team of international relocation specialists has extensive
              experience in navigating the complexities of moving abroad. From
              customs regulations to shipping logistics, we have the knowledge
              and expertise to ensure a seamless transition to your new
              destination.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Personalized Service</p>
            <p className="content">
              We understand that every international relocation is unique, which
              is why we offer personalized service tailored to your specific
              needs and preferences. Whether you're moving for work, education,
              or retirement, we'll work closely with you to create a customized
              relocation plan that meets your needs and budget.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Comprehensive Support</p>
            <p className="content">
              From packing and shipping to customs clearance and delivery, we
              provide end-to-end support throughout the international relocation
              process. Our team will handle all the logistics of your move,
              allowing you to focus on settling into your new home and
              community.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Global Network</p>
            <p className="content">
              Through our extensive network of international partners and
              agents, we have the resources and connections to facilitate smooth
              and efficient relocations to destinations around the world. No
              matter where your journey takes you, we'll be there every step of
              the way to ensure a successful move.
            </p>
          </div>
        </Col>
        <Col lg="12">
          <p className="sub-head">Our International Relocation Services</p>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Pre-Move Planning</p>
            <p className="content">
              Our relocation specialists will work with you to develop a
              comprehensive plan for your international move, covering
              everything from packing and shipping to immigration requirements
              and cultural considerations.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Packing and Shipping</p>
            <p className="content">
              We offer professional packing and shipping services to ensure that
              your belongings are safely and securely transported to your new
              destination. Whether you're moving household goods, vehicles, or
              pets, we'll handle all the logistics of your move with care and
              precision.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Customs Clearance</p>
            <p className="content">
              Navigating customs regulations can be complex, but our team is
              here to help. We'll assist you with all the necessary paperwork
              and documentation required for customs clearance, ensuring a
              smooth and efficient process.
            </p>
          </div>
        </Col>
        <Col lg="3">
          <div className="why_choose">
            <p className="title">Destination Services</p>
            <p className="content">
              Once you arrive at your new destination, our team can provide a
              range of destination services to help you settle in and get
              acclimated to your new surroundings. From finding housing to
              enrolling in schools and accessing local amenities, we're here to
              support you every step of the way.
            </p>
          </div>
        </Col>
        <Col lg="12">
          <p className="content text-black mt-4">
            At KHALSA PACKERS AND MOVERS PVT LTD, we're committed to providing
            top-quality international relocation services that you can trust.
            Whether you're moving across the border or around the globe, you can
            rely on us to handle all the logistics of your move with
            professionalism, care, and attention to detail.
          </p>
          <Link to="/#enquiryForm" className="text-black text-decoration-none">
            <div className="button-conclusion d-flex justify-content-center mt-5 align-items-center">
              <img loading="lazy"src={call} alt="Call" className="me-4" />
              <p className="text-black text-decoration-none">
                Contact us today to learn more about our international
                relocation services and how we can help you with your global
                journey.
              </p>
            </div>
          </Link>
        </Col>
      </Row>
    </section>
  );
};

export default InternationalRelocation;
