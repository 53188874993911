import React from "react";
import CustomBreadcrumb from "../../components/common/Breadcrumb/Custom_Breadcrumb";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import "./index.scss";
import CitywiseEnquiry from "../../components/UI/CitywiseEnquiry/CitywiseEnquiry";

const Munger = () => {
  return (
    <section className="citywise-place">
      <CustomBreadcrumb
        name="Citywise"
        secondName="Packers and Movers in Munger"
      />
      <Container>
        <Row className="m-0 mt-5 p-3 p-lg-5">
          <Col lg="8">
            <CitywiseEnquiry />

            <p className="citywise_place mt-5">Packers And Movers In Munger</p>
            <p className="citywise_description mt-3">
              Moving from one apartment to another in the same city can be a
              daunting task; however, first, we had to depend on ourselves to
              make sure everything was packed and moved safely; the services of
              packers and movers have come to the rescue to move quickly. Khalsa
              Packers and Movers Pvt. Ltd. Munger are service providers that
              deal in furniture, suitcases, Pack and move goods, including
              artefacts and furnishings, among others, securely. This helps in
              reducing the running process as these service providers become our
              one-stop shop for in-place solutions. Process moves? Time to call
              packers and movers agency. Make your pick based on whether you're
              moving within the city or elsewhere.
            </p>
            <p className="sub-head">Hire professionals</p>
            <p className="citywise_description">
              Hire a Khalsa Packers and Movers Pvt. Ltd. if you are moving
              within the city. If moving to another location, choose a service
              provider that operates in multi-cities to shortlist and take
              quotations based on available services. Choose the one that
              provides you with complete services at an affordable cost. In
              addition, credibility checks are essential. Therefore, your social
              media platforms andRead customer feedback on the company's
              website. Once in the final form, they should provide you with a
              document detailing the products they are moving and ensuring their
              safety. Go through the document and read the damage policy to know
              the terms and conditions regarding the safety and security of the
              goods. A good service provider has to maintain these details.
            </p>
            <br />
            <p className="sub-head">Dedicated team</p>
            <p className="citywise_description">
              This Khalsa Packers and Movers Pvt. Ltd. Munger team will proceed
              with your items and deliver them to your new home. You must first
              provide them with their loss and damage policy for you to decide
              on choosing them as your service provider. They can provide you
              with an optional insurance cover for your belongings. Insurance
              covering loss or damage of any possession in an unforeseen
              circumstance
            </p>
            <p className="sub-head">Insurance of belongings</p>
            <p className="citywise_description">
              Once insured, in case of an accident, you will be compensated. It
              is the team's responsibility to ensure that you are packing and
              weighing around; the service provider must notify the customer in
              advance of the teams that will serve them. They should provide you
              with the team's name, contact details and other necessary
              information. Once you arrive, check your identity cards and
              cross-check them with the information you have provided. It is the
              responsibility of the service provider to keep an eye on your
              belongings.
            </p>
          </Col>
          <Col lg="4">
            <div className="our-services p-1 p-lg-3">
              <p className="header">Our Services</p>

              <ul>
                <Link to="/services/international-relocation">
                  <li>International Relocation</li>
                </Link>
                <li>
                  <Link to="/services/transportation-services">
                    Transportation Services
                  </Link>
                </li>
                <li>
                  <Link to="/services/warehousing-and-storage">
                    Warehousing and Storage
                  </Link>
                </li>
                <li>
                  <Link to="/services/loading-and-unloading">
                    Loading and Unloading
                  </Link>
                </li>
                <li>
                  <Link to="/services/office-relocation">
                    Office Relocation
                  </Link>
                </li>
                <li>
                  <Link to="/services/car-transportation">
                    Car Transportation
                  </Link>
                </li>
                <li>
                  <Link to="/services/bike-transportation">
                    Bike Transportation
                  </Link>
                </li>
                <li>
                  <Link to="/services/intercity-moves">Intercity Moves</Link>
                </li>
                <li>
                  <Link to="/services/transit-insurance">
                    Transit Insurance
                  </Link>
                </li>
                <li>
                  <Link to="/services/heavy-machinery-shifting">
                    Heavy Machinery Shifting
                  </Link>
                </li>
              </ul>
            </div>
            <div className="location mt-5 p-3 aspect-ratio aspect-ratio-16by9">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28840.064422465755!2d86.45284368958984!3d25.371046593214377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f1eee66aa3ebc7%3A0x1bcf4fdc391adc06!2sMunger%2C%20Bihar%20811201!5e0!3m2!1sen!2sin!4v1714390559205!5m2!1sen!2sin"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="Munger"
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Munger;
