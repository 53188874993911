import React from "react";
import Slider from "react-slick";
import { HeroSlider1, HeroSlider2, HeroSlider3 } from "../../../assets/images";
import "./HeroSection.scss";
import ButtonPrimary from "../../common/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../common/ButtonSecondary/ButtonSecondary";
import { Link, useNavigate } from "react-router-dom";

const HeroSection = () => {
  const navigate = useNavigate();
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 910,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="position-relative hero-section">
      <Slider className="hero_slider" {...settings}>
        <img
          src={HeroSlider1}
       alt="Hero_Slider"
          className="w-100 img-fluid slider-image"
        />
        <img
          src={HeroSlider2}
       alt="Hero_Slider"
          className="w-100 img-fluid slider-image"
        />
        <img
          src={HeroSlider3}
          alt="Hero_Slider"
   className="w-100 img-fluid slider-image"
        />
      </Slider>
      <div className="position-absolute hero_content">
        <p className="title">Govt. of India Registered</p>
        <p className="title">Packers & Movers</p>
        <p className="description mt-2 mt-lg-5 ">
          We are offering smooth and world-class packing and moving services
          from Bhagalpur according to your all relocation needs.
        </p>
        <div className="mt-5 d-flex justify-content-center flex-column  flex-lg-row ">
          <ButtonPrimary
            content="CONTACT US"
            className="py-2 py-lg-0"
            onClick={() => {
              navigate("/contact-us");
            }}
          />
          <Link to="tel://8541893616" className="">
            <ButtonSecondary
              content="CALL US: +91 8541893616"
              className="ms-0 ms-lg-5 mt-2 mt-lg-0 w-100 w-lg-max"
            />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
