import React from "react";
import "./PartnerDirector.scss";
import { IBA, balbir, sarwjeet } from "../../../assets/images";
import { Link } from "react-router-dom";

const PartnersDirector = () => {
  return (
    <section className="partners-directors p-5 d-flex flex-column align-items-center">
      <div className="affiliate text-center">
        <p className="heading my-4">AFFILIATE PARTNER (IBA)</p>
        <img loading="lazy"src={IBA} alt="Indian Bank Association" className="img-fluid" />
      </div>
      <div className="directors mt-5">
        <p className="heading">DIRECTOR PROFILE</p>
        <div className="director_profile mt-5">
          <div className="p-5 pb-2">
            <img loading="lazy"src={balbir} alt="BALBIR" className="img-fluid" />
          </div>
          <p className="mt-1 mb-4 text-center name">Balbir Singh</p>
        </div>
        <div className="director_profile">
          <div className="p-5 pb-2">
            <Link
              to="https://www.facebook.com/sarwjeet.singh.731"
              target="_blank"
            >
              <img loading="lazy"src={sarwjeet} alt="SARWJEET" className="img-fluid" />
            </Link>
          </div>
          <p className="name mt-1 mb-4 text-center">Sarwjeet Singh</p>
        </div>
      </div>
    </section>
  );
};

export default PartnersDirector;
